

<nav class="barraNavegacion navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
  <a class="navbar-brand logo-image" href=""><img src="./assets/images/prevenir/logo.png" style="width: 150px;height: 48px;" /></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navBarPrev" aria-controls="navBarPrev" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="navBarPrev">
      <ul class="navbar-nav ml-auto">
        <li class=" nav-item" >
          <a class="nav-link" [routerLink]="['/inicio']" routerLinkActive="active-navegacion" >INICIO</a>
       </li>
       <li class=" nav-item">
          <a class="nav-link" [routerLink]="['/quienes-somos']" routerLinkActive="active-navegacion" >QUIENES SOMOS?</a>
      </li>
      <li class=" nav-item">
        <a class="nav-link" [routerLink]="['/planes']" routerLinkActive="active-navegacion" >PLANES</a>
    </li>
     <li class=" nav-item ">
      <a class="nav-link" [routerLink]="['/galeria']" routerLinkActive="active-navegacion" >GALERIA</a>
    </li>
     <li class=" nav-item ">
      <a class="nav-link" [routerLink]="['/obituario']" routerLinkActive="active-navegacion" >OBITUARIO</a>
    </li>
    <li class=" nav-item ">
      <a class="nav-link" [routerLink]="['/contactenos']" routerLinkActive="active-navegacion" >CONTACTO</a>
    </li>
    <li class=" nav-item ">
      <a class="nav-link" [routerLink]="['/terminos-condiciones']" routerLinkActive="active-navegacion" >TÉRMINOS Y CONDICIONES</a>
    </li>
      </ul>
  </div>
</nav>
