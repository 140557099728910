import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-form-libranza',
  templateUrl: './form-libranza.component.html',
  styleUrls: ['./form-libranza.component.css']
})
export class FormLibranzaComponent implements OnInit {

  @ViewChild('contenedor', {static : true}) contenedor;

  public datosForm: FormGroup;
  public departamento = new FormControl ( '', [ Validators.required ] );
  public formBeneficiario: any = []
  public tamaño: number
  public tamañoPeluditos: number
  public planBasico: any
  public planGeneral: any;
  public buscarDepasMunis;
  public departamentos;
  public municipios;
  public valorSuscripcion = 25000;
  public validarFormulario: string;
  public parentesco;
  public today;
  public beneficiarios = [];
  public peluditos = []
  public infoBeneficiarios= [];
  public status: string;
  public statusText: string;
  public intervalo

  constructor( private formBuilder: FormBuilder,
               private appService: AppService ) { 
                 this.today = moment( new Date().toString()).format( 'YYYY-MM-DD' )
               }

  ngOnInit() {
    this.inicializarFormulario();
    this.getDepartamentos();
    this.getParentesco();
    this.departamento.valueChanges.subscribe( (_) => {
      this.departamentoSelect()
    } )
  }

  inicializarFormulario(){
    this.datosForm = this.formBuilder.group({
      nombreCompleto : ['', [Validators.required, Validators.minLength(3), Validators.pattern('([a-zA-Z ñ]+) ([a-zA-Z ñ]+) ([a-zA-Z ñ]+)')]],
      tipoDocumento : ['', [Validators.required]],
      numeroIdentificacion : ['', [Validators.required,Validators.pattern('[0-9]*')] ],
      fechaNacimiento: [ '', [ Validators.required ] ],
      email : ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      numeroCelular : ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(7)]],
      numeroCelular2 : ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(7)]],
      municipio : ['', [Validators.required]],
      direccion : ['', [Validators.required]],
      barrio : ['', [Validators.required]],
      profesion : [''],
      empresaDondeLabora : [''],
    })
  }

  getParentesco(){
    this.appService.getParentesco().subscribe( (parentesco:any) => {
      this.parentesco = parentesco.body;
    }, err => console.log( 'parentesco', err ) )
  };

  departamentoSelect() {
    this.selecionarMunicipio(this.departamento.value);
  }

  getDepartamentos() {
    this.appService.getDepartamentos().subscribe( (departamentos: any) => {
      this.departamentos = departamentos.body;
      //console.log(this.departamentos);
    }, err => {
    console.log('error get departamentos');
    } );
    
  }

  selecionarMunicipio(Municipio) {
    this.buscarDepasMunis = null;
    (<HTMLInputElement>document.getElementById('idmunicipio')).value = Municipio.nombreMunicipio + ' - ' + Municipio.nombreDepartamento;
    this.datosForm.get('municipio').setValue(Municipio.nombreMunicipio);
  }

  deparMuniBuscar(event:string){
    this.buscarDepasMunis = event
  }

  msgError( campo:string ){
    const errors = this.datosForm.get(campo).errors
    if ( errors.required ){
      return 'Este campo es requerido.'
    } else if ( errors.pattern ){
      return 'El formato ingresado es invalido.'
    }else if ( errors.minlength ) {
      return 'Cantidad minima de caracteres (7).'
    }

    return ''
  }

  campoInvalido( campo: string ){
    return this.datosForm.get(campo).invalid && this.datosForm.get(campo).touched
  }

  verEdad(){
    let fecha = moment( this.datosForm.get('fechaNacimiento').value )
    let edad = parseInt(fecha.fromNow())
    //console.log(fecha)
    //console.log('la edad es: '+edad)

    return edad;
  }

  seleccionPlan(){
    this.planBasico = (<HTMLInputElement>document.getElementById('planBasico')).checked
    //console.log(this.planBasico)
    this.planGeneral = (<HTMLInputElement>document.getElementById('planGeneral')).checked
    //console.log(this.planGeneral)
    if ( this.planBasico === true ) {
      this.valorSuscripcion = 20000;
      this.tamaño = 7
      //console.log(this.tamaño)
    }
    if (this.planGeneral === true ) {
      this.valorSuscripcion = 25000
      this.tamaño = 9
      //console.log(this.tamaño)
    }
  }

  obtenerDatosB(){

    if (this.datosForm.valid) {
      
      for (let posicion = 0; posicion < this.formBeneficiario.length; posicion++) {
        
        let formValido = [{campo : 'nombreYapellido', bol: false },
                           {campo : 'tipoDocumento', bol: false },
                           {campo : 'parentesco', bol: false },
                           {campo : 'numeroDocumento', bol: false },
                           {campo : 'fechaBeneficiario', bol: false },
                           {campo : 'numeroCelular', bol: false }];
    
         // nombre y apellido
         let nombreYapellido: any = document.getElementById('nombreYapellido' + posicion);
         nombreYapellido = nombreYapellido.value;
         //console.log(nombreYapellido)
         formValido[0].bol = true;
         // Tipo de documento
         let tipoDocumento: any = document.getElementById('tipoDocumento' + posicion);
         tipoDocumento = tipoDocumento.value;
         //console.log(tipoDocumento)
         formValido[1].bol = true;
         // Parentesco
         let parentesco: any = document.getElementById('parentesco' + posicion);
         parentesco = parentesco.value;
         //console.log(parentesco)
         formValido[2].bol = true;
         // número de documento
         let numeroDocumento: any = document.getElementById('numeroDocumento' + posicion);
         numeroDocumento = numeroDocumento.value;
         //console.log(numeroDocumento)
         formValido[3].bol = true;
         // Fecha de nacimiento
         let fechaBeneficiario: any = document.getElementById('fechaBeneficiario' + posicion);
         fechaBeneficiario = fechaBeneficiario.value;
         //console.log(fechaBeneficiario)
         formValido[4].bol = true;
         // Numero de celular
         let numeroCelular: any = document.getElementById('numeroCelular' + posicion);
         numeroCelular = numeroCelular.value;
         //console.log(numeroCelular)
         formValido[5].bol = true;
    
        this.beneficiarios.push({
          nombreYapellido,
          tipoDocumento,
          numeroDocumento,
          parentesco,
          fechaBeneficiario,
          numeroCelular
        })
        
      }
      
      let info = { titular: this.datosForm.value, beneficiarios: this.beneficiarios}
      console.log(info)
  
      // this.appService.enviarDatos(info).subscribe();
  
      this.status = 'EXITO'
      this.statusText = 'Tus datos han sido enviados y una persona te llamara para completar la inscripcion'
    }else{
      this.status = 'FALLO'
      this.statusText = 'Ha ocurrido un error tal vez no lleno completamente los datos verifique de nuevo por favor.'
    }
  

    //console.log(this.datosUsuario.value)
    //console.log(this.beneficiarios)
    //console.log(this.infoBeneficiarios)
    //console.log(this.formsBeneficiario)
    
  }

  obtenerDatosPeludito(){

    if (this.planBasico === true || this.planGeneral === true) {
      
      let nombre: any = document.getElementById('nombrePeludito')
      nombre = nombre.value
      //console.log(nombre)
  
      let tipoP: any = document.getElementById('tipo-perro')
      tipoP = tipoP.checked
      //console.log(tipoP)
  
      let tipoG: any = document.getElementById('tipo-gato')
      tipoG = tipoG.checked
      //console.log(tipoG)
  
      if (tipoP === true) {
        //console.log('perro')
        tipoP = document.getElementById('tipo-perro')
        tipoP = tipoP.value
      }else if (tipoG === true) {
        //console.log('Gato')
        tipoG = document.getElementById('tipo-gato')
        tipoG = tipoG.value
      }
  
      let raza: any = document.getElementById('razaPeludito')
      raza = raza.value
      //console.log(raza)
  
      let edad: any = document.getElementById('edadPeludito')
      edad = edad.value
      //console.log(edad)
  
      let macho: any = document.getElementById('macho')
      macho = macho.checked
      //console.log(macho)
  
      let hembra: any = document.getElementById('hembra')
      hembra = hembra.checked
      //console.log(hembra)
  
      if (macho === true) {
        //console.log('Macho')
        macho = document.getElementById('macho')
        macho = macho.value
      } else if (hembra === true) {
        //console.log('Hembra')
        hembra = document.getElementById('hembra')
        hembra = hembra.value
      }
  
      this.peluditos.push({
        nombre,
        edad,
        raza,
        tipoP, tipoG,
        macho, hembra,
      })
  
      //console.log(this.peluditos)
      this.status = 'EXITO'
      this.statusText = 'Su peludito ha sido guardado con exito, gracias por confiar a su mascota con nosotros'
    }else{
      this.status = 'FALLO'
      this.statusText = 'HA OCURRIDO UN ERROR! Tal vez no ingreso los datos de su peludito correctamente o no escogio un plan, por favor ingreselos de nuevo'
    }
  }

  agregarBeneficiario(){

    if (this.formBeneficiario.length <= 0) {
      if ( this.validacionesFormularios() === 'valido' && this.planBasico === true || this.planGeneral === true ) {
        this.formBeneficiario.push( {formulario: 'form'} );
        this.intervalo = setInterval( (_) => {
          document.getElementById('btn-scroll').click();
        }, 1 )
      }else{
        this.datosForm.touched
        this.status = 'error';
        this.statusText = 'Por favor llena los campos requeridos o selecciona un plan';
      }
    }else{
      
      let posicion = this.formBeneficiario.length - 1;
      //console.log('posicion: '+posicion)

      if (posicion < this.tamaño) {
        let formValido = [{campo : 'nombreYapellido', bol: false },
                          {campo : 'tipoDocumento', bol: false },
                          {campo : 'parentesco', bol: false },
                          {campo : 'numeroDocumento', bol: false },
                          {campo : 'fechaBeneficiario', bol: false },
                          {campo : 'numeroCelular', bol: false }];
  
        // nombre y apellido
        let nombreYapellido: any = document.getElementById('nombreYapellido' + posicion);
        nombreYapellido = nombreYapellido.value;
  
        if (this.validacionCadenaDeTexto(nombreYapellido, 'nombreYapellido', posicion) === 'caracteres_validos') {
         formValido[0].bol = true;
        }
  
        // Tipo de documento
        let tipoDocumento: any = document.getElementById('tipoDocumento' + posicion);
        tipoDocumento = tipoDocumento.value;
        
        if (this.validacionesRequire(tipoDocumento, 'tipoDocumento', posicion) === 'valido') {
         formValido[1].bol = true;
        }
  
        // Parentesco
        let parentesco: any = document.getElementById('parentesco' + posicion);
        parentesco = parentesco.value;
        
        if (this.validacionesRequire( parentesco, 'parentesco', posicion ) === 'valido') {
          formValido[2].bol = true;
        }
  
        // número de documento
        let numeroDocumento: any = document.getElementById('numeroDocumento' + posicion);
        numeroDocumento = numeroDocumento.value;
        
        if (this.validacionNumeros(numeroDocumento, 'numeroDocumento', posicion) === 'numero_valido') {
          formValido[3].bol = true;
        }
  
        // Fecha de nacimiento
        let fechaBeneficiario: any = document.getElementById('fechaBeneficiario' + posicion);
        fechaBeneficiario = fechaBeneficiario.value;
        
        if (this.validacionesRequire( fechaBeneficiario, 'fechaBeneficiario', posicion ) === 'valido'){
          formValido[4].bol = true;  
        }
  
        // Numero de celular
        let numeroCelular: any = document.getElementById('numeroCelular' + posicion);
        numeroCelular = numeroCelular.value;
        formValido[5].bol = true;
  
        var bol: boolean;
  
         //  console.log(formValido);
         // tslint:disable-next-line: prefer-for-of
         for (let i = 0; i < formValido.length; i++) {
  
          //console.log(bol)
  
          bol = formValido[i].bol;
          if (bol === false) {
              console.log('por aquii');
              break;
          }
         }
      }

       if (bol === true) {
        this.formBeneficiario.push({formulario : 'form'});
       }
    }

  }

  validacionesFormularios(): string {

    // CASOS

   // SOLO SE INCRIBA EL TITULAR

   if (this.formBeneficiario.length <= 0) {

     if (this.datosForm.valid) {
         //  console.log('valido');
          this.validarFormulario = 'valido';
          return 'valido';
     } else {
          this.validarFormulario = 'invalido';
          return 'invalido';
         //  console.log('invalido');
     }
  } else {
     // TITULAR Y BENEFICIARIOS
     // tslint:disable-next-line: prefer-for-of
     for (let i = 0; i < this.tamaño; i++) {

           let formValido = [{campo : 'nombreYapellido', bol: false },
                         {campo : 'tipoDocumento', bol: false },
                         {campo : 'parentesco', bol: false },
                         {campo : 'numeroDocumento', bol: false },
                         {campo : 'fechaBeneficiario', bol: false },
                         {campo : 'numeroCelular', bol: false }];

           // Nombre
           let nombreYapellido: any = document.getElementById('nombreYapellido' + i);
           nombreYapellido = nombreYapellido.value;
           //console.log(nombreYapellido)
           formValido[0].bol = true;

           // Validaciones nombre
           /* if (this.validacionCadenaDeTexto(nombreYapellido, 'nombreYapellido', i) === 'caracteres_validos') {
           } */

           // Tipo de documento
           let tipoDocumento: any = document.getElementById('tipoDocumento' + i);
           tipoDocumento = tipoDocumento.value;
           //console.log(tipoDocumento)
           formValido[1].bol = true;

           // Validaciones tipo de documento
           /* if (this.validacionesRequire(tipoDocumento, 'tipoDocumento', i) === 'valido') {
           } */

           // Parentesco
           let parentesco: any = document.getElementById('parentesco' + i);
           parentesco = parentesco.value;
           //console.log(parentesco)
           formValido[2].bol = true;

           // Validaciones de parentesco
           /* if (this.validacionesRequire(parentesco, 'parentesco', i) === 'valido') {
           } */

           // número de documento
           let numeroDocumento: any = document.getElementById('numeroDocumento' + i);
           numeroDocumento = numeroDocumento.value;
           //console.log(numeroDocumento)
           formValido[3].bol = true;

           // validaciones numero de documento
           /* if (this.validacionNumeros(numeroDocumento, 'numeroDocumento', i) === 'numero_valido') {
           } */

           // Fecha de nacimiento
           let fechaBeneficiario: any = document.getElementById('fechaBeneficiario' + i);
           fechaBeneficiario = fechaBeneficiario.value;
           //console.log(fechaBeneficiario)
           formValido[4].bol = true;

           // Validaciones fecha de nacimiento
           /* if (this.validacionesRequire(fechaBeneficiario, 'fechaBeneficiario', i) === 'valido') {
           } */

           // Numero de celular
           let numeroCelular: any = document.getElementById('numeroCelular' + i);
           numeroCelular = numeroCelular.value;
           //console.log(numeroCelular)
           formValido[5].bol = true;

           // validaciones numero de celular
           /* if (this.validacionNumeros(numeroCelular, 'numeroCelular', i) === 'numero_valido') {
           } */

           /* let bol = false;
           if ( formValido[0].bol === false ||
                formValido[1].bol === false ||
                formValido[2].bol === false ||
                formValido[3].bol === false ||
                formValido[4].bol === false ||
                formValido[5].bol === false 
              ) {
                bol = false;
                return 'invalido';
           } else {
             bol = true;
           } */

          //console.log(nombreYapellido, tipoDocumento, parentesco, numeroDocumento, fechaBeneficiario, numeroCelular);
           this.infoBeneficiarios.push({nombreYapellido,
                                       tipoDocumento,
                                       parentesco,
                                       numeroDocumento,
                                       fechaBeneficiario,
                                       numeroCelular});
         }

     // console.log(this.infoBeneficiarios);
     return 'valido';

     // console.log(bol);

     // if (bol === true) {
     //   return 'valido';
     // } else {
     //   return 'invalido';
     // }

   }

 }

  validacionCadenaDeTexto(texto: string, tipo: string, posicion): string {
    // console.log(texto, tipo, posicion);
  
    var caracteresInvalidos = '0123456789*/-_!|()#.,<>°+';
  
    if (tipo === 'nombreYapellido')  {
        if (texto) {
          for (let j = 0; j < texto.length; j++) {
            if (caracteresInvalidos.indexOf(texto.charAt(j), 0) != -1) {
              document.getElementById('nombreYapellido' + posicion).className = 'form-control is-invalid';
              document.getElementById('textno' + posicion).style.display = '';
              return 'caracteres_invalidos';
            } else {
              document.getElementById('nombreYapellido' + posicion).className = 'form-control';
              document.getElementById('textno' + posicion).style.display = 'none';
              return 'caracteres_validos';
            }
          }
        } else {
          document.getElementById('nombreYapellido' + posicion).className = 'form-control is-invalid';
          document.getElementById('textn' + posicion).style.display = '';
        }
      }
    }
  
  validacionNumeros(numero: string, tipo: string, posicion: number): string {
    if (tipo === 'numeroDocumento')  {
  
      if (numero) {
        if ( numero.length < 5 || numero.length > 15 ) {
        // console.log('aqui');
        document.getElementById('numeroDocumento' + posicion).className = 'form-control is-invalid';
        document.getElementById('textndo' + posicion).style.display = '';
        return 'numero_invalido';
        }
        document.getElementById('numeroDocumento' + posicion).className = 'form-control';
        document.getElementById('textndo' + posicion).style.display = 'none';
        document.getElementById('numeroDocumento' + posicion).className = 'form-control';
        document.getElementById('textnd' + posicion).style.display = 'none';
        return 'numero_valido';
      } else {
        document.getElementById('numeroDocumento' + posicion).className = 'form-control is-invalid';
        document.getElementById('textnd' + posicion).style.display = '';
      }
  
    } else {
  
      if (numero) {
  
        if ( numero.length < 7 || numero.length > 15 ) {
  
          document.getElementById('numeroCelular' + posicion).className = 'form-control is-invalid';
          document.getElementById('textnce' + posicion).style.display = '';
          return 'numero_invalido';
        }
  
        document.getElementById('numeroCelular' + posicion).className = 'form-control';
        document.getElementById('textnce' + posicion).style.display = 'none';
        document.getElementById('numeroCelular' + posicion).className = 'form-control';
        document.getElementById('textnc' + posicion).style.display = 'none';
        return 'numero_valido';
  
      } else {
        document.getElementById('numeroCelular' + posicion).className = 'form-control is-invalid';
        document.getElementById('textnc' + posicion).style.display = '';
      }
    }
  }
  
  validacionesRequire(value, tipo: string, posicion: number): string {
  
    switch (tipo) {
  
      case 'tipoDocumento' :
  
        if (value) {
          document.getElementById('tipoDocumento' + posicion).className = 'form-control';
          document.getElementById('texttdo' + posicion).style.display = 'none';
          return 'valido';
        } else {
          document.getElementById('tipoDocumento' + posicion).className = 'form-control is-invalid';
          document.getElementById('texttdo' + posicion).style.display = '';
          return 'invalido';
        }
      break;
  
      case 'parentesco' :
  
        if (value) {
          document.getElementById('parentesco' + posicion).className = 'form-control';
          document.getElementById('textpa' + posicion).style.display = 'none';
          return 'valido';
        } else {
          document.getElementById('parentesco' + posicion).className = 'form-control is-invalid';
          document.getElementById('textpa' + posicion).style.display = '';
          return 'invalido';
        }
      break;
  
      case 'fechaBeneficiario' :
  
        if (value) {
          document.getElementById('fechaBeneficiario' + posicion).className = 'form-control';
          document.getElementById('textfn' + posicion).style.display = 'none';
          return 'valido';
        } else {
          document.getElementById('fechaBeneficiario' + posicion).className = 'form-control is-invalid';
          document.getElementById('textfn' + posicion).style.display = '';
          return 'invalido';
        }
      break;
    }
  }

  eliminarFormulario(index) {
    this.formBeneficiario.splice(index, 1);
    this.beneficiarios.splice(index, 1);
  }

  cambioHtml(ev, tipo, posicion) {
    // console.log(tipo);
    var value: any = document.getElementById(ev.target.id);
    value = value.value;
  
    switch (tipo) {
  
     case 'nombreYapellido' :
     this.validacionCadenaDeTexto(value, tipo, posicion);
     break;
  
     case 'tipoDocumento' :
     this.validacionesRequire(value, tipo, posicion);
     break;
  
     case 'parentesco' :
     this.validacionesRequire(value, tipo, posicion);
     break;
  
     case 'fechaBeneficiario' :
     this.validacionesRequire(value, tipo, posicion);
     break;
  
     case 'numeroCelular' :
     this.validacionNumeros(value, tipo, posicion);
     break;
  
     case 'numeroDocumento' :
     this.validacionNumeros(value, tipo, posicion);
     break;
    }
  }

  cerrarAlerta() {
    this.statusText = '';
    this.status = '';
  }

  scroll() {
    let coordenadaY: number;
    coordenadaY = this.contenedor.nativeElement.offsetHeight;
    // console.log('aqui scroll', coordenadaY);
    window.scrollTo(0 , coordenadaY);
    clearInterval(this.intervalo);
  }

}
