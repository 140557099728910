<!-- <app-menu></app-menu> -->

<div #contenedor id="contenedor" class="container">
    <div class="text-center">
        <h4 class="display-4 text-white" style="font-family: 'roboto';">Formulario de inscripción individual</h4>
    </div>

    <!-- // BTN OCULTO PARA SCROLL -->
    <button id="btn-scroll" style="display: none;" (click)="scroll()"></button>

    <div class="row bg-white" style="margin-top: 50px;">

        <!-- ----------------------------------------------------------------------------------------------------- -->
        <!-- -------------------------------------------- FORMULARIO TITULAR ------------------------------------- -->
        <!-- ----------------------------------------------------------------------------------------------------- -->

        <div class="col-10">

                <div class="col-md-5" (change)="obtenerTipo()">
                        <label class="form-label mr-3">eres persona o empresa?</label>
                        <br>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="formPersona" data-toggle="form" data-target="#persona">
                          <label class="form-check-label" for="formPersona"> <strong>Persona natural</strong> </label>
                        </div>
                        <div class="form-check form-switch form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="formEmpresa" data-toggle="form" data-target="#empresa">
                          <label class="form-check-label" for="formEmpresa"> <strong>empresa</strong> </label>
                        </div>
                        <hr>
                </div>


                 <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
                     {{statusText}}
                     <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                       <span aria-hidden="true">&times;</span>
                     </button>
                 </div>

                <form [formGroup]="datosUsuario" #persona>
                        <div class="row g-3" *ngIf="obtenerTipo() === 'persona'">

                                <!-- ----------------------------------------- FECHA DE NACIMIENTO -------------------------------------- -->
                                <div class="col-md-4">


                                        <label class="form-label">Fecha de nacimiento</label>
                                        <input type="date" class="form-control" [max]="today" formControlName="fechaNacimiento" (change)="valorSuscripcionTi()">
                                                <hr>
                                                
                                        
                                        <!-- ----------------------------------------- VALIDACION FECHA NACIMIENTO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('fechaNacimiento')">

                                                {{ msgError('fechaNacimiento') }}
                                        </span>

                                        <span class="form-text text-danger"
                                                *ngIf="verEdad() < 18 || verEdad() > 75">

                                                La edad del usuario ingresado es menor de 18 años o es mayor a 74 años
                                        </span>
                                        
                                </div>
                                
                                <!-- ----------------------------------------- NOMBRES Y APELLIDOS -------------------------------------- -->

                                <div class="col-md-4">
                                        <div class="formGroup">
                                                <label for="nombreCompleto" class="inp">
                                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z"/></svg> -->
                                                        <input type="text" class="form-control" formControlName="nombreCompleto" placeholder="&nbsp;">
                                                        <span class="label">Nombre completo</span>
                                                        <span class="focus-bg"></span>
                                                </label>
                                                <hr>
                                                
                                        </div>

                                        <!-- ----------------------------------- VALIDACION NOMBRES ----------------------------- -->
                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('nombreCompleto')">

                                                {{ msgError('nombreCompleto') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- TIPO DOCUMENTO -------------------------------------- -->
                                <div class="col-md-4">
                                    <label class="form-label">Tipo documento</label>
                                    <select class="form-control" formControlName="tipoDocumento" [class.is-invalid]="(datosUsuario.get('tipoDocumento').errors && datosUsuario.get('tipoDocumento').touched) ||
                                                                                                                     (datosUsuario.get('tipoDocumento').errors) && (datosUsuario.invalid && (validarFormulario === 'invalido' )) ">
                                        <option value=""> -- Seleccione tipo de documento -- </option>
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="CE">Cédula de Extranjería</option>
                                        <option value="PA">Pasaporte</option>
                                        <option value="RC">Registro Civil</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                    </select>
                                    <hr>
                            
                                    <!-- ----------------------------------------- TIPO DOCUMENTO VALIDACIONES -------------------------------------- -->
                                    <span class="form-text text-danger"
                                                *ngIf="campoInvalido('tipoDocumento')">

                                                {{ msgError('tipoDocumento') }}
                                        </span>
                                </div>
                                
                                 <!-- ----------------------------------------- NUMERO DOCUMENTO -------------------------------------- -->

                                <div class="col-md-4">
                                        <label for="numeroIdentificacion" class="inp">
                                                <input type="number" formControlName="numeroIdentificacion" min="0" placeholder="&nbsp;">
                                                <span class="label">Numero documento</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        <!-- ----------------------------------------- VALIDACION NUMERO DOCUMENTO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('numeroIdentificacion')">

                                                {{ msgError('numeroIdentificacion') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- EMAIL -------------------------------------- -->
                                
                                <div class="col-md-4">
                                        <label for="email" class="inp">
                                                <input type="email" formControlName="email" placeholder="&nbsp;">
                                                <span class="label">Correo</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- -----------------------------------------VALIDACION EMAIL -------------------------------------- -->
                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('email')">

                                                {{ msgError('email') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- NUMERO DE CELULAR -------------------------------------- -->

                                <div class="col-md-4">

                                        <label for="numeroCelular" class="inp">
                                                <input type="number" formControlName="numeroCelular" min="0"placeholder="&nbsp;">
                                                <span class="label">Numero celular</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- -----------------------------------------VALIDACION NUMERO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('numeroCelular')">

                                                {{ msgError('numeroCelular') }}
                                        </span>

                                </div>

                                <!-- ----------------------------------------- NUMERO DE CELULAR 2 -------------------------------------- -->

                                <div class="col-md-4">

                                        <label for="numeroCelular2" class="inp">
                                                <input type="number" formControlName="numeroCelular2" min="0"placeholder="&nbsp;">
                                                <span class="label">Numero celular respaldo</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- -----------------------------------------VALIDACION NUMERO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('numeroCelular2')">

                                                {{ msgError('numeroCelular2') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- DEPARTAMENTO Y MUNICIPIO -------------------------------------- -->
                                
                                <div class="col-md-4 mt-1">

                                        <label for="municipio" class="inp">
                                                <input autocomplete="off" class="form-control text-center" type="text" id="idmunicipio" placeholder="&nbsp;" (keyup)="deparMuniBuscar($event.target.value)" required>
                                                <span class="label">Municipio/Departamento</span>
                                                <span class="focus-bg"></span>
                                        </label>
                                        <div class="panel-body list-group text-primary" style="background-color: #e6e6e6;">
                                                <div class="seleccionado form-material" *ngFor="let dm of departamentos | busqueda: buscarDepasMunis" style="cursor: pointer;">
                                                        <p class="text-center" (click)="selecionarMunicipio(dm)">
                                                                {{ dm.nombreMunicipio }} - {{ dm.nombreDepartamento }}
                                                        </p>
                                                </div>
                                        </div>
                                        <hr>
                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('municipio')">

                                                {{ msgError('municipio') }}
                                        </span>

                                </div>

                                <!-- ----------------------------------------- DIRECCION ---------------------------------------->

                                <div class="col-md-4">
                                        <label for="direccion" class="inp">
                                                <input type="text" formControlName="direccion" placeholder="&nbsp;">
                                                <span class="label">Direccion</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- ----------------------------------------- VALIDACIONES DIRECCION  -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('direccion')">
                                                {{ msgError('direccion') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- BARRRIO -------------------------------------- -->
                                <div class="col-md-4">

                                        <label for="barrio" class="inp">
                                                <input type="text" formControlName="barrio" placeholder="&nbsp;">
                                                <span class="label">Barrio</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- -----------------------------------------VALIDACION BARRRIO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalido('barrio')">
                                                {{ msgError('barrio') }}
                                        </span>

                                </div>

                                <div class="col-md-4">
                                        <label for="profesion" class="inp">
                                                <input type="text" formControlName="profesion" placeholder="&nbsp;">
                                                <span class="label">profesion</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                </div>
                
                                <div class="col-md-4">
                                        <label for="empresaDondeLabora" class="inp">
                                                <input type="text" formControlName="empresaDondeLabora" placeholder="&nbsp;">
                                                <span class="label">Empresa donde trabaja</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                </div>
                        
                                <div class="col-md-5 mt-3" *ngIf="formsBeneficiario.length > 3">
                                    <label class="form-label mr-3">Activar plan peluditos?</label>
                                    <br>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planPeluditosS" data-toggle="modal" data-target="#modalFPeluditos">
                                      <label class="form-check-label" for="planPeluditosS"> <strong>Si</strong> </label>
                                    </div>
                                    <div class="form-check form-switch form-check-inline">
                                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planPeluditosN" checked>
                                      <label class="form-check-label" for="planPeluditosN"> <strong>No</strong> </label>
                                    </div>
                                    <hr>
                                </div>
                            
                                <div class="modal" id="modalFPeluditos" role="dialog">
                                    <div class="modal-dialog modal-dialog-centered" style="background: transparent;">
                                        <div class="modal-content" style="background: transparent;">
                                            <div class="modal-body text-center">
                                                <div class="text-center">
                                                <app-form-peluditos></app-form-peluditos>
                                                <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosPeludito()">Guardar</button>
                                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

       
                </form>

                <form [formGroup]="datosEmpresa" #empresa>

                        <div class="row g-3" *ngIf="obtenerTipo() === 'empresa'">

                                <div class="col-md-5">
                                        <label for="nombreEmpresa" class="inp">
                                                <input type="text" formControlName="nombreEmpresa" placeholder="&nbsp;">
                                                <span class="label">Nombre empresa</span>
                                                <span class="focus-bg"></span>
                                            </label>

                                        <!-- ----------------------------------- VALIDACION NOMBRES ----------------------------- -->
                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('nombreEmpresa')">

                                                {{ msgErrorE('nombreEmpresa') }}
                                        </span>
                                </div>

                                <div class="col-md-4">
                                        <label for="nitEmpresa" class="inp">
                                                <input type="number" class="form-control" min="0" formControlName="nitEmpresa" placeholder="&nbsp;">
                                                <span class="label">Nit empresa</span>
                                                <span class="focus-bg"></span>
                                                <hr>       
                                        </label>

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('nitEmpresa')">

                                                {{ msgErrorE('nitEmpresa') }}
                                        </span>
                                </div>

                                <div class="col-md-4">
                                        <label for="correoEmpresa" class="inp">
                                                <input type="email" class="form-control" formControlName="correoEmpresa" placeholder="&nbsp;">
                                                <span class="label">Correo empresa</span>
                                                <span class="focus-bg"></span>
                                                <hr>
                                        </label>

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('correoEmpresa')">

                                                {{ msgErrorE('correoEmpresa') }}
                                        </span>
                                </div>

                                <div class="col-md-4">
        
                                        <label for="numeroEmpresa" class="inp">
                                                <input  type="number"
                                                        class="form-control"
                                                        minlength="7"
                                                        formControlName="numeroEmpresa"
                                                        placeholder="&nbsp;">
                                                        <hr>
                                                <span class="label">Numero empresa</span>
                                                <span class="focus-bg"></span>
                                        </label>

                                        <!-- -----------------------------------------VALIDACION NUMERO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('numeroEmpresa')">

                                                {{ msgErrorE('numeroEmpresa') }}
                                        </span>

                                </div>

                                <div class="col-md-4">
        
                                        <label for="numeroEmpresa2" class="inp">
                                                <input  type="number"
                                                class="form-control"
                                                minlength="7"
                                                formControlName="numeroEmpresa2"
                                                placeholder="&nbsp;">
                                                <hr>
                                                <span class="label">Numero respaldo</span>
                                                <span class="focus-bg"></span>
                                        </label>

                                        <!-- -----------------------------------------VALIDACION NUMERO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('numeroEmpresa2')">

                                                {{ msgErrorE('numeroEmpresa2') }}
                                        </span>

                                </div>

                                <div class="col-md-4 mt-2">

                                        <label for="municipio" class="inp">
                                                <input autocomplete="off" class="form-control text-center" type="text" id="idmunicipio" placeholder="&nbsp;" (keyup)="deparMuniBuscar($event.target.value)" required>
                                                <span class="label">Municipio/departamento</span>
                                                <span class="focus-bg"></span>
                                        </label>
                                        <div class="panel-body list-group text-primary" style="background-color: #e6e6e6;">
                                                <div class="seleccionado form-material" *ngFor="let dm of departamentos | busqueda: buscarDepasMunis" style="cursor: pointer;">
                                                        <p class="text-center" (click)="selecionarMunicipio(dm)">
                                                                {{ dm.nombreMunicipio }} - {{ dm.nombreDepartamento }}
                                                        </p>
                                                </div>
                                        </div>
                                        <hr>

                                </div>

                                <div class="col-md-4">
                                        <label for="direccionEmpresa" class="inp">
                                                <input type="text"
                                                class="form-control"
                                                formControlName="direccionEmpresa"
                                                placeholder="&nbsp;">
                                                <hr>
                                                <span class="label">Direccion empresa</span>
                                                <span class="focus-bg"></span>
                                        </label>

                                        <!-- ----------------------------------------- VALIDACIONES DIRECCION  -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('direccionEmpresa')">
                                                {{ msgErrorE('direccionEmpresa') }}
                                        </span>
                                </div>

                                <!-- ----------------------------------------- BARRRIO -------------------------------------- -->
                                <div class="col-md-4">

                                        <label for="barrio" class="inp">
                                                <input type="text"
                                                class="form-control"
                                                formControlName="barrio"
                                                placeholder="&nbsp;">
                                                <hr>
                                                <span class="label">Barrio</span>
                                                <span class="focus-bg"></span>
                                        </label>

                                        <!-- -----------------------------------------VALIDACION BARRRIO -------------------------------------- -->

                                        <span class="form-text text-danger"
                                                *ngIf="campoInvalidoE('barrio')">
                                                {{ msgErrorE('barrio') }}
                                        </span>

                                </div>

                        </div>

                </form>
                
                <!-- ----------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------- FORMULARIOS BNEFICIARIO ----------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------- -->                
                
                <div *ngIf="formsBeneficiario.length >= 1">

                        <div  class="input-group"
                                *ngFor="let fb of formsBeneficiario; let i = index">
        
                                <div class="row" style="margin-top: 40px;">
        
                                        <div class="col-8  text-left">
                                          <h3 class="card-title">Formulario beneficiario No. {{i + 1}}</h3>
                                        </div>
                                         <div class="col-4 text-right">
                                                <button class="btn btn-danger" (click)="eliminarFormulario(i)">Eliminar</button>
                                        </div>
        
                                </div>

                                <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
                                        {{statusText}}
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                </div>

                                <form class="row g-3"> 
                                        <div class="col-md-3">
                                                <label class="form-label">Fecha nacimiento</label>
                                                <input type="date" class="form-control" [max]="today" placeholder="Fecha de nacimiento." [id]="'fechaBeneficiario' + i" (change)="valorBeneficiario()">
                                                <p [id]="'textfn' + i" style="color: red; font-size: 13px; display: none;">* Este campo es requerido.</p>
                                        </div>
                                        <div class="col-md-5">
                                                <label for="nombreYapellido" class="inp">
                                                        <input type="text" [id]="'nombreYapellido' + i" class="form-control" placeholder="&nbsp;" pattern="'[A-Z a-z ñ]* [A-Z a-z ñ]*'" >
                                                        <span class="label">Nombre completo</span>
                                                        <span class="focus-bg"></span>
                                                </label>
                                                <p [id]="'textn' + i" style="color: red; font-size: 13px; display: none;">* Este campo es requerido.</p>
                                                <p [id]="'textno' + i" style="color: red; font-size: 13px; display: none;">* Este campo solo acepta letras.</p>
                                        </div>
                                        <div class="div-md-4">
                                                <label class="form-label">tipo Documento</label>
                                                <select class="form-control" [id]="'tipoDocumento' + i" (change)="cambioHtml($event, 'tipoDocumento', i)">
                                                                                             
                                                        <option value=""> -- Seleccione tipo de documento -- </option>
                                                        <option value="CC">Cédula de Ciudadanía</option>
                                                        <option value="CE">Cédula de Extranjería</option>
                                                        <option value="PA">Pasaporte</option>
                                                        <option value="RC">Registro Civil</option>
                                                        <option value="TI">Tarjeta de Identidad</option>
                                                </select>
    
                                        <!-- ----------------------------------------- TIPO DOCUMENTO VALIDACIONES -------------------------------------- -->
                                                <p [id]="'texttdo' + i" style="color: red; font-size: 13px; display: none;">* Este campo es requerido.</p>
                                        </div>
                                        <div class="col-md-4">
                                                <label for="numeroDocumento" class="inp">
                                                        <input type="number" class="form-control" min="0" placeholder="&nbsp;" [id]="'numeroDocumento' + i" (keypress)="cambioHtml($event, 'numeroDocumento', i)" >
                                                        <span class="label">Numero documento</span>
                                                        <span class="focus-bg"></span>
                                                </label>
                                                <p [id]="'textnd' + i" style="color: red; font-size: 13px; display: none;">* Este campo es requerido.</p>
                                                <p [id]="'textndo' + i" style="color: red; font-size: 13px; display: none;">* Número invalido.</p>
                                        </div>
                                        <div class="col-md-4">
                                                <label class="form-label">Parentesco</label>
                                                <select class="form-control" [id]="'parentesco' + i" (change)="cambioHtml($event, 'parentesco', i)">
                                                        <option disabled></option>
                                                        <option *ngFor="let p of parentesco" [value]="p.nombreParentesco">{{p.nombreParentesco}}</option>
                                                        <option value="empleado"  *ngIf="obtenerTipo() === 'empresa'"> Empleado </option>
                                                </select>
                                                <p [id]="'textpa' + i" style="color: red; font-size: 13px; display: none;">* Este campo es requerido.</p>
                                        </div>
                                        <div class="col-md-4">
                                                <label for="numeroCelular" class="inp">
                                                        <input  type="number"
                                                        class="form-control"
                                                        [id]="'numeroCelular' + i"
                                                        placeholder="&nbsp;">
                                                        <span class="label">Numero celular</span>
                                                        <span class="focus-bg"></span>
                                                </label>
                                        </div>
                                        
                                </form>
                                
                        </div>
                </div>                
                
                <div class="modal" id="modalFormulario" role="dialog" *ngIf="obtenerTipo() === 'persona'">
                        <div class="modal-dialog modal-dialog-centered " style="background: transparent;">
                                 <div class="modal-content">
                                         <div class="modal-body text-center">
                                                <h3 style="font-family:'roboto'"> Los datos utilizados seran enviados a un asesor para confirmar la inscripcion. </h3>
                                                <hr>
                                                <h5> Confirma los siguientes datos ingresados? </h5>
                                                <h5> CC: {{datosUsuario.get('numeroIdentificacion').value}} </h5>
                                                <h5> Correo: {{datosUsuario.get('email').value}} </h5>
                                                <h5> Telefono: {{datosUsuario.get('numeroCelular').value}} </h5>

                                                <div class="text-center">
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosB()">Si</button>
                                                        
                                                        <button type="button" class="btn btn-danger"data-dismiss="modal">No</button>
                                                </div>
                                         </div>
                                 </div>
                        </div>
                </div>

                <div class="modal" id="modalFormulario" role="dialog" *ngIf="obtenerTipo() === 'empresa'">
                        <div class="modal-dialog modal-dialog-centered " style="background: transparent;">
                                 <div class="modal-content">
                                         <div class="modal-body text-center">
                                                <h3 style="font-family:'roboto'"> Los datos utilizados seran enviados a un asesor para confirmar la inscripcion. </h3>
                                                <hr>
                                                <h5> Confirma los siguientes datos ingresados? </h5>
                                                <h5> NIT: {{datosEmpresa.get('nitEmpresa').value}} </h5>
                                                <h5> Correo: {{datosEmpresa.get('correoEmpresa').value}} </h5>
                                                <h5> Telefono: {{datosEmpresa.get('numeroEmpresa').value}} </h5>

                                                <div class="text-center">
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosB()">Si</button>
                                                        
                                                        <button type="button" class="btn btn-danger"data-dismiss="modal">No</button>
                                                </div>
                                         </div>
                                 </div>
                        </div>
                </div>

                <!-- ----------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------- BTN AGREGAR BNEFICIARIO ----------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------- -->

                <div class="text-center" style="padding-top: 30px;">
                        <button class="btn btn-primary" (click)="agregarBeneficiario()">Agregar Beneficiario</button>
                        <!--<button class="btn btn-primary" style="margin-left: 5px;" (click)="generarRecibo()">Generar recibo</button>-->
                </div>

                <div class="col-10">


                        <div class="row">
                            <!-- ----------------------------------------------------------------------------------------------------- -->
                            <!-- ------------------------------------------ CARD VALOR SUSCRIPCION ----------------------------------- -->
                            <!-- ----------------------------------------------------------------------------------------------------- -->
                            <div class="col-10 mb-2">
                                <div class="carta" style="padding-top: 15px;">
                                    <div class="card border-dark rounded-3">
                                        <div class="card-body">
                                            <h5 class="card-title">Resumen de suscripción</h5>
            
                                            <div class="row">
                                                <div class="col-8">
                                                    <p>Valor suscripcion por año</p>
                                                </div>
                                                <div class="col-4 text-right">$ {{valorSuscripcionTitular}}</div>
                                            </div>
            
                                            <div class="row">
                                                <div class="col-8">
                                                    <p>Número de beneficiarios</p>
                                                </div>
                                                <div class="col-4 text-right">{{formsBeneficiario.length}}</div>
                                            </div>
            
                                            <div class="row">
                                                <div class="col-8">
                                                    <p>Total beneficiarios</p>
                                                </div>
                                                <div class="col-4 text-right">$ {{valorSuscripcionBeneficiario * formsBeneficiario.length}}</div>
                                            </div>
            
                                            <div class="row">
                                                <div class="col-8">
                                                    <h5>Total</h5>
                                                </div>
                                                <div class="col-4 text-right">$ {{(valorSuscripcionBeneficiario * formsBeneficiario.length) + valorSuscripcionTitular}}</div>
                                            </div>
            
                                            <div class="card-header"></div>
                                            <div class="text-center" style="padding-top: 5px;">
                                                <button class="btn btn-primary"
                                                        data-toggle="modal"
                                                        data-target="#modalFormulario">
                                                        Inscribirse
                                                </button>
                                            </div>

                                                <div class="mb-3 row">
                                                        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                        </symbol>
                                                        </svg>
        
                                                        <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="status === 'EXITO'">
                                                          <svg class="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
                                                          <div>
                                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            {{statusText}}
                                                          </div>
                                                        </div>
                                                </div>

                                                <div class="mb-3 row">
                                                        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                        </symbol>
                                                        </svg>
        
                                                        <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="status === 'FALLO'">
                                                          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                                                          <div>
                                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                                  <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            {{statusText}}
                                                          </div>
                                                        </div>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
</div>

<app-footer></app-footer>

<!-- <h2>Valido: {{datosUsuario.valid}} </h2>

<h2>USUARIO</h2>
<pre> {{ datosUsuario.value | json }} </pre>

<h2>BENEFICIARIO</h2>
<pre> {{ formsBeneficiario.valid }} </pre> -->
