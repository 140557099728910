<app-menu></app-menu>
<a target="_blank"  href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn-flotante">
	<h4 class="text-white mai-logo-whatsapp"></h4> </a>
<div class="row">
	<div class=col-md-12> <img src="./assets/images/prevenir/familia.jpg" style="max-width: 100%; padding-top: 65px;  width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;"> </div>
</div>
<div class="bg-image imgFondo">
	<div class="row">
		<div class="col-md-12">
			<br>
			<h1 class="text-center " style="color: white !important"> Planes para nuestros clientes. </h1>
			<br> </div>
		<div class="col-md-3">
			<br>
			<div class="container ">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/Medico2.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>DESCUENTOS <br> MÉDICOS</h2> <a href="https://api.whatsapp.com/send?phone=573174785093&amp;text=Hola!%20quiero%20inscribirme%20a%20Descuentos%20Médicos!" target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#descuentosMedicos">Ver más</a> </div>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<br>
			<div class="container">
				<div class="card">
					<div class="imgBx "> <img src="./assets/images/prevenir/familia-numerosas.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>PLAN<br>LIBRANZA</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Libranza." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#libranza">Ver más</a> </div>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<br>
			<div class="container">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/familiaPequeña.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>PLAN <br> INDIVIDUAL</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Individual." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#individualsaa"> Ver más </a> </div>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<br>
			<div class="container">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/abuelitoss.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>PLAN <br> PATRIARCA</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Patriarca." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#patriarca">Ver más</a> </div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-1"></div>
		<div class="col-md-3">
			<br>
			<div class="container ">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/mascotass.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>PLAN <br>  PELUDITOS</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Peluditos." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#peluditos">Ver más</a> </div>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<br>
			<div class="container">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/planVehii.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2>PLAN <br> VEHÍCULAR</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Vehicular." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#vehiculs">Ver más</a> </div>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<br>
			<div class="container">
				<div class="card">
					<div class="imgBx"> <img src="./assets/images/prevenir/internacionall.jpg" class="rounded-circle imagenPrev"> </div>
					<div class="contentBx">
						<h2 style="font-size:30px">PLAN <br> INTERNACIONAL</h2> <a href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Internacional." target="_blank">Adquirir</a>
						<br>
						<br> <a type="button" class="btn btn-secondary" data-toggle="modal" data-target="#internacionals">Ver más</a> </div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade modalFotos" id="descuentosMedicos" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="">
					<h3 class="text-center" style="padding-bottom: 0 !important"> PLAN DESCUENTOS MEDICOS </h3>
					<h6 class="text-center "> Valores agregados para nuestros afiliados </h6>
					<div class="row">
						<div class="col-md-1"></div>
						<div class="col-md-10">
							<h5 class="recuadro">* Descuentos en servicios médicos, odontológicos, clínicos y de laboratorio</h5> </div>
						<div class="col-md-1"></div>
					</div>
					<div class="row" style="padding-top: 50px">
						<div class="col-md-1"></div>
						<div class="col-md-5"> <img src="./assets/images/prevenir/Medico2.jpg" class=" imagenPrev"> </div>
						<div class="col-md-5">
							<ul class="rss" style="padding-top: 50px">
								<li>Medicina General</li>
								<li>Especialistas</li>
								<li>Medicina del dolor</li>
								<li>Laboratorio clínico</li>
								<li>Exámenes Especializados</li>
								<li>Fisioterapia</li>
							</ul>
						</div>
						<div class="col-md-1"></div>
					</div>
					<div class="row" style="padding-top: 50px">
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<h5 class="recuadro">* Descuentos en atención y diagnostico veterinario</h5>
							<div class="row" style="padding-top: 50px">
								<div class="col-md-4 justify-content-center"> <img src="./assets/images/prevenir/martillo.png" class="imagen" alt="juridico">
									<h5 style="text-align: center">Prevenir Jurídico</h5> </div>
								<div class="col-md-4 justify-content-center"> <img src="./assets/images/prevenir/dinero.png" class="imagen" alt="juridico">
									<h5 style="text-align: center">Prevenir Contable</h5> </div>
								<div class="col-md-4 justify-content-center"> <img src="./assets/images/prevenir/idea.png" class="imagen" alt="juridico">
									<h5 style="text-align: center">Psicológico</h5> </div>
							</div>
						</div>
						<div class="col-md-5"> <img src="./assets/images/prevenir/veterinario.PNG" class="imagenPrev">
							<h5 class="recuadro">* Descuentos en atención y diagnostico veterinario</h5> </div>
						<div class="col-md-1"></div>
					</div>
					<div class="row" style="padding-top: 50px">
						<div class="col-md-1"></div>
						<div class="col-md-12">
							<h3 style="text-align: center">Valores agregados para nuestros afiliados</h3>
							<div class="row">
								<div class="col-md-1"></div>
								<div class="col-md-5"> <img src="./assets/images/prevenir/paciente.jpg" class="imagenPrev"> </div>
								<div class="col-md-5">
									<ul class="rss" style="padding-top: 50px">
										<li>Jóvenes saludabales</li>
										<li>Mujer libre de cancer de cuello uterino</li>
										<li>Mujer libre de cáncer de mama</li>
										<li>Programa de salud visual</li>
										<li>Programa de salud oral</li>
									</ul>
								</div>
								<div class="col-md-1"></div>
							</div>
						</div>
						<div class="col-md-1"></div>
					</div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573174785093&amp;text=Hola!%20quiero%20inscribirme%20a%20Descuentos%20Médicos!" target="_blank" type="button" class="btn btn-success">Adquirir</a>
			</div>
		</div>
	</div>
</div>


<div class="modal fade modalFotos" id="libranza" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-1"></div>
					<div class="col-md-5">
						<h3 class="text-center " style="padding-bottom: 30px !important"> GENERAL LIBRANZA </h3> <img src="./assets/images/prevenir/familia-numerosa.jpg" style="max-width: 100%; width: 800px; display: block;margin: auto; border-radius: 20px;"> </div>
					<div class="col-md-5">
						<h4 class="">Dos planes diseñados para su comodidad</h4>
						<br/>
						<div class="recuadro">
							<h5 style="color: white">PLAN GERENCIAL: 10 miembros de su familia
                                  </h5> </div>
						<br/>
						<div class="recuadro">
							<h5 style="color: white">PLAN BÁSICO: 8 miembros de su familia.
                                  </h5> </div>
						<br>
						<div class="recuadro">
							<h5 style="color: white">Titular + beneficiarios hasta los 69 años – previamente inscritos “hijos, hermanos, primos, tíos, sobrinos, cuñados, nueras, yernos, padrastros, hijastros, hermanastros, exesposo(a), uno sin parentesco. Padres o
                                      suegros sin límite de edad máximo (2) dos los otros dos si los tuviese hasta los 84 años “(máximo 2 Peluditos con carencia 180 días, valido solo para la ciudad de Pasto)” Todos los beneficiarios mayores a 65 años carencia obligatoria 90 Días.
                                  </h5> </div>
					</div>
					<div class="col-md-1"></div>
				</div>
				<div class="text-center">
					<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Libranza." target="_blank" type="button" class="btn btn-success">Adquirir</a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade modalFotos" id="individualsaa" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" style="background: transparent;">
		<div class="modal-content  " style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-6">
						<h3 class="text-center " style="padding-bottom: 30px !important"> PLAN INDIVIDUAL </h3> <img src="./assets/images/prevenir/familiaPeque.jpg" style="max-width: 100%; width: 800px; display: block;margin: auto; border-radius: 20px;"> </div>
					<div class="col-md-6">
						<br/>
						<div class="recuadro2">
							<h5 style="color: white">PLAN INDIVIDUAL: Un plan diseñado para familias pequeñas con el objetivo que paguen solo por su nucleo básico con un pago único por persona al año.</h5> </div>
						<br/>
						<div class="recuadro">
							<p style="color: white">Ejemplo: grupo Familiar de (3) personas C/u:
								<br> $ 25.000 * 3 personas = $ 75.000 El Año
								<br> 
								<br> *Titular y beneficiarios hasta los 74 años
								<br> Se extra prima de acuerdo a la edad de
								<br> 65 a 69 años $30.000
								<br> 70 a 75 Años $35.000
								<br> Máximo un (1) Peluditos por grupo familiar por cada 4 integrantes con un precio anual por Peludito de $60.000 el Año. (valido solo para la ciudad de Pasto) </p>
							<p style="color: white">Se puede financiar máximo a tres pagos, planes superiores a cinco (5) integrantes.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
				        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Individual." type="button" target="_blank" class="btn btn-success">Adquirir</a>

			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<div class="modal fade modalFotos" id="patriarca" tabindex="-1" role="dialog" aria-labelledby="scrollableModalTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" role="document" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-1"></div>
					<div class="col-md-5">
						<h3 class="text-center " style="padding-bottom: 30px !important"> PLAN PATRIARCA </h3> <img src="./assets/images/prevenir/abuelitos.jpg" style="max-width: 100%; width: 500px; display: block;margin: auto; border-radius: 20px;"> </div>
					<div class="col-md-5">
						<br/>
						<div class="recuadro2">
							<h5 style="color: white">Es un plan diseñado para comprar de manera anticipada y preventiva para nuestros seres queridos mayores de 84 años que por la edad No Aplican a nuestros planes y así tener tranquilidad en momentos difíciles “este plan no incrementa
                                      el costo ni vence con el tiempo”. $ 2.480.000.oo dividido máximo en 18 cuotas</h5> </div>
						<br/>
						<div class="recuadro">
							<p style="color: white">Beneficios: Sin límite de edad, Sin restricción de salud, Se adquiere en propiedad, Transferible, Su valor se mantiene por lo cual es un ahorro.
								<br> Si en el momento de fallecer su ser querido el usuario no ha terminado de cancelar las cuotas. Pagara el excedente al activar el servicio funerario.</p>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
				        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Patriarca." target="_blank" type="button" class="btn btn-success">Adquirir</a>

			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<div class="modal fade modalFotos" id="peluditos" tabindex="-1" role="dialog" aria-labelledby="scrollableModalTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" role="document" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-1"></div>

					<div class="col-md-5">
						<h3 class="text-center " style="padding-bottom: 0 !important"> PLAN PELUDITOS “Este servicio solo es para perros y gatos.” </h3>
						<ul class="rss">
							<br>
							<li>Retiro de la mascota del lugar de fallecimiento (dentro del perímetro urbano).</li>
							<li>Vehículo para tres acompañantes. “Opcional”</li>
							<li>Cofre fúnebre para velación. </li>
							<li>Sala de velación</li>
							<li>Lote por tres (3) años o Cremación y cofre para las cenizas.</li>
						</ul> <b style="font-size:18px;"><p>“Cementerio jardín de paz cielo animal o Cremación para la mascota”.</p></b>
						<ul class="rss">
							<li> “Carencia de 180 días.</li>
							<li>Peso Max plan 40 kilos. los kilos adicionales los paga el doliente en el momento del servicio funerario</li>
							<li>Obligatorio sesión fotográfica mínimo 10 fotos por Peludito.</li>
							<li>Máximo un Peludito por cada cuatro familiares. NO se puede contratar solo el plan mascotas”</li>
							<li>Aplica solo para Pasto Nariño Colombia.</li>
							<br> </ul>
					</div>
					<div class="col-md-5" style="display: flex;align-items: center;justify-content: center;"> <img src="./assets/images/prevenir/mascotas.jpg" style="max-width: 100%; width: 800px; display: block;margin: auto;"> </div>
					<div class="col-md-1"></div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
				        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Peluditos." target="_blank" type="button" class="btn btn-success">Adquirir</a>

			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<div class="modal fade modalFotos" id="vehiculs" tabindex="-1" role="dialog" aria-labelledby="scrollableModalTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" role="document" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-1"></div>
					<div class="col-md-5">
						<h3 class="text-center " style="padding-bottom: 30px !important"> PLAN VEHICULAR </h3> <img src="./assets/images/prevenir/planVehi.jpg" style="max-width: 100%; width:400px; display: block;margin: auto; border-radius: 20px;"> </div>
					<br/>
					<div class="col-md-5">
						<br/>
						<div class="recuadro2">
							<h5 style="color: white"> Es un plan diseñado para proteger los ocupantes de su vehiculo de forma inmediata en caso de muerte en siniestro por accidente de transito a nivel nacional.
                                      <br> El plan cubre en caso de muerte en accidente de transito a todos los ocupantes del vehiculo involucrado sin que estén inscritos.
                                      <br> Los gastos funerarios son: 
									  <br> cobramos al SOAT quien tiene un rubro máximo 150 SMDLV para estos casos.
                                  </h5> </div>
						<br/>
						<div class="recuadro">
							<h6 style="color: white">Un solo Pago de $49.990 por vehiculo el año “Automvil o motocicleta” servicio particular.</h6> </div>
					</div>
					<div class="col-md-1"></div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
				        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Vehicular." target="_blank" type="button" class="btn btn-success">Adquirir</a>

			</div>
		</div>
		<!-- /.modal-content -->
	</div>
	<!-- /.modal-dialog -->
</div>
<div class="modal fade modalFotos" id="internacionals" tabindex="-1" role="dialog" aria-labelledby="scrollableModalTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" role="document" style="background: transparent;">
		<div class="modal-content" style="background: transparent;">
			<div class="modal-body">
				<div class="row" style="padding-top: 50px">
					<div class="col-md-1"></div>
					<div class="col-md-5">
						<h3 class="text-center " style="padding-bottom: 0 !important"> PLAN INDIVIDUAL INTERNACIONAL </h3>
						<br>
						<div class="recuadro2">
							<h5 style="color: white"> Plan para nuestros seres queridos que están por fuera del país, Repatriación como Expatriación Adicional al plan Nacional. Con un valor de $ 199.990 El Año por Persona.

                                  </h5> </div>
						<br/>
						<div class="recuadro">
							<h6 style="color: white">Carencias :
                                      <br> 1. 72 HORAS PARA MUERTE ACCIDENTAL
                                      <br> 2. 90 DÍAS PARA MUERTE NATURAL
                                      <br> 3. 180 DÍAS PARA ENFERMEDADES PRE-EXISTENTES.
                                  </h6> </div>
					</div>
					<div class="col-md-5" style="display: flex;align-items: center;justify-content: center;"> <img src="./assets/images/prevenir/internacional.png" style="max-width: 100%; width: 800px; display: block;margin: auto;"> </div>
					<div class="col-md-1"></div>
				</div>
			</div>
			<div class="text-center">
				<button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
				        <a style="margin: 10px" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20adquirir%20el%20plan%20Internacional." target="_blank" type="button" class="btn btn-success">Adquirir</a>

			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</div>
<app-footer></app-footer>
