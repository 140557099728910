<!-- <app-menu></app-menu> -->

<div #contenedor id="contenedor" class="container">

    <!-- <div class="text-center">
        <h3 class="display-3 text-white" style="font-family: 'roboto';">PLAN PELUDITOS</h3>
    </div> -->

    <!-- // BTN OCULTO PARA SCROLL -->
    <button id="btn-scroll" style="display: none;" (click)="scroll()"></button>

    <div class="row" style="margin-top: 50px; background: #807182; ">

        <div class="col-10">

              <div class='signup-container'>
                  <div class='left-container'>
                    <h1>
                      <i class='fas fa-paw'></i>
                      PREVENIR EXPRESS
                    </h1>
                    <div class='puppy'>
                      <img src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/38816/image-from-rawpixel-id-542207-jpeg.png'>
                    </div>
                  </div>
                  <div class='right-container'>
                    <header>
                      <h1>Dale a tu mascota el mejor serivicio</h1>
                      <div class='set'>
                        <div class='pets-name'>
                          <label for='nombrePeludito'>Nombre</label>
                          <input id='nombrePeludito' name="nombrePeludito" placeholder="Nombre peludito" type='text'>
                        </div>
                        <div class='pets-spayed-neutered'>
                          <label for='tipo'>Perro o Gato</label>
                          <div class='radio-container'>
                            <input id='tipo-perro' name='tipo' type='radio' value='Perro'>
                            <label for='tipo-perro'>Perro</label>
                            <input id='tipo-gato' name='tipo' type='radio' value='Gato'>
                            <label for='tipo-gato'>Gato</label>
                          </div>
                        </div>
                      </div>
                      <div class='set'>
                        <div class='pets-breed'>
                          <label for='razaPeludito'>Raza</label>
                          <input id='razaPeludito' name="razaPeludito" placeholder="Raza" type='text'>
                        </div>
                        <div class='pets-birthday'>
                          <label for='edadPeludito'>Edad</label>
                          <input id='edadPeludito' min="0" max="20" name="edadPeludito" type='number'>
                        </div>
                      </div>
                      <div class='set'>
                        <div class='pets-gender'>
                          <label for='pet-gender-female'>Genero</label>
                          <div class='radio-container'>
                            <input id='hembra' name='genero' type='radio' value='Hembra'>
                            <label for='hembra'>Hembra</label>
                            <input id='macho' name='genero' type='radio' value='Macho'>
                            <label for='macho'>Macho</label>
                          </div>
                        </div>
                      </div>
                    </header>
                    <!-- <footer>
                      <div class='set'>
                        <button id='back'>Back</button>
                        <button id='next'>Next</button>
                      </div>
                    </footer> -->
                  </div>
                </div>

              

        </div>

    </div>


</div>

<!-- <app-footer></app-footer> -->