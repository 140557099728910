import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-form-vehicular',
  templateUrl: './form-vehicular.component.html',
  styleUrls: ['./form-vehicular.component.css']
})
export class FormVehicularComponent implements OnInit {

  @ViewChild('contenedor', {static : true}) contenedor;

  public datosUsuario: FormGroup
  public departamento = new FormControl ('', Validators.required);
  public buscarDepasMunis;
  public departamentos;
  public municipios;
  public intervalo;
  public valorSuscripcionTitular = 49900;
  public today;
  public status: string;
  public statusText: string;

  constructor( private formBuilder: FormBuilder,
                private appService: AppService ) { 
                  this.today = moment(new Date().toString()).format('YYYY-MM-DD');
                }

  ngOnInit() {
    this.inicializarDatos();
    this.getDepartamentos();
    this.departamento.valueChanges.subscribe( (_) => {
      this.departamentoSelect()
    } );
  }

  inicializarDatos(){
    this.datosUsuario = this.formBuilder.group({
      nombreCompleto: [ '', [ Validators.required, Validators.pattern('([a-zA-Z ñ]+) ([a-zA-Z ñ]+) ([a-zA-Z ñ]+)') ] ],
      tipoDocumento: [ '', [ Validators.required ] ],
      numeroDocumento: [ '', [ Validators.required, Validators.pattern('([0-9]*)') ] ],
      email: [ '', [ Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") ] ],
      numeroCelular: [ '', [ Validators.required, Validators.pattern('([0-9]*)') ] ],
      municipio: [ '', [ Validators.required ] ],
      placaVehiculo: [ '', [ Validators.required ] ],
      marca: [ '', [ Validators.required ] ],
      modelo: [ '', [ Validators.required ] ],
      cilindraje: [ '', [ Validators.required ] ],
      color: [ '', [ Validators.required ] ],
      servicio: [ '', [ Validators.required ] ],
      tipo: [ '', [ Validators.required ] ],
    })
  }

  departamentoSelect() {
    this.selecionarMunicipio(this.departamento.value);
  }

  getDepartamentos() {
    this.appService.getDepartamentos().subscribe( (departamentos: any) => {
      this.departamentos = departamentos.body;
      //console.log(this.departamentos);
    }, err => {
    console.log('error get departamentos');
    } );
    
  }

  selecionarMunicipio(Municipio) {
    this.buscarDepasMunis = null;
    (<HTMLInputElement>document.getElementById('idmunicipio')).value = Municipio.nombreMunicipio + ' - ' + Municipio.nombreDepartamento;
    this.datosUsuario.get('municipio').setValue(Municipio.nombreMunicipio);
  }

  deparMuniBuscar(event:string){
    this.buscarDepasMunis = event
  }

  msgError( campo:string ){
    const errors = this.datosUsuario.get(campo).errors
    if ( errors.required ){
      return 'Este campo es requerido.'
    } else if ( errors.pattern ){
      return 'El formato ingresado es invalido.'
    }else if ( errors.minlength ) {
      return 'Cantidad minima de caracteres (7).'
    }

    return ''
  }

  campoInvalido( campo: string ){
    return this.datosUsuario.get(campo).invalid && this.datosUsuario.get(campo).touched
  }

  obtenerDatosB(){
    if (this.datosUsuario.valid) {
      let info = { titular: this.datosUsuario.value, beneficiarios: 'Como es plan vehicular no tiene beneficiarios'}
      console.log(info)
  
      //this.appService.enviarDatos(info).subscribe();
  
      this.status = 'EXITO'
      this.statusText = 'Tus datos han sido enviados y una persona te llamara para completar la inscripcion'
    }else{
      this.status = 'FALLO'
      this.statusText = 'HA OCURRIDO UN ERROR! Tal vez no lleno completamente los datos, por favor verifique de nuevo.'
    }
  }

  verEdad(){
    let fecha = moment( this.datosUsuario.get('fechaNacimiento').value )
    let edad = parseInt(fecha.fromNow())
    //console.log(fecha)
    //console.log('la edad es: '+edad)

    return edad;
  }



  cerrarAlerta() {
    this.statusText = '';
    this.status = '';
  }
  
  scroll() {
    let coordenadaY: number;
    coordenadaY = this.contenedor.nativeElement.offsetHeight;
    // console.log('aqui scroll', coordenadaY);
    window.scrollTo(0 , coordenadaY);
    clearInterval(this.intervalo);
  }

}
