import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-form-peluditos',
  templateUrl: './form-peluditos.component.html',
  styleUrls: ['./form-peluditos.component.css']
})
export class FormPeluditosComponent implements OnInit {

  @ViewChild('contenedor', {static : true}) contenedor;

  public datosPeludito: FormGroup;
  public valorSuscripcionTitular = 2480000;
  public today;
  public status: string;
  public statusText: string;
  public intervalo;

  constructor( private formBuilder: FormBuilder,
                private appService: AppService ) {
                  this.today = moment( new Date().toString()).format('YYYY-MM-DD')
                 }

  ngOnInit() {
    this.inicializarPeludito()
  }

  inicializarPeludito(){
    this.datosPeludito = this.formBuilder.group({
      nombrePeludito: [ '', [ Validators.required, Validators.pattern('([a-zA-Z ñ]+)') ] ],
      razaPeludito: [ '', [ Validators.required ] ],
      edadPeludito: [ '', [ Validators.required ] ],
    })
  }

  scroll() {
    let coordenadaY: number;
    coordenadaY = this.contenedor.nativeElement.offsetHeight;
    // console.log('aqui scroll', coordenadaY);
    window.scrollTo(0 , coordenadaY);
    clearInterval(this.intervalo);
  }

}
