<!-- <app-menu></app-menu> -->

<div #contenedor id="contenedor" class="container">

    <div class="text-center">
        <h3 class="display-3 text-white" style="font-family: 'roboto';">PLAN PATRIARCA</h3>
    </div>

    <!-- // BTN OCULTO PARA SCROLL -->
    <button id="btn-scroll" style="display: none;" (click)="scroll()"></button>

    <div class="row bg-white" style="margin-top: 50px; ">

        <div class="col-10">

            <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
                {{statusText}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form [formGroup]="datosUsuario">

                <div class="row g-3">

                    <div class="col-md-3 mt-2">
                        <label class="form-label">Fecha Nacimiento</label>
                            <input  type="date"
                                    class="form__input"
                                    [max]="today"
                                    formControlName="fechaNacimiento">
        
                            <span class="form-text text-danger"
                                    *ngIf="campoInvalido('fechaNacimiento')">
        
                                    {{ msgError('fechaNacimiento') }}
                            </span>
        
                            <span class="form-text text-danger"
                                    *ngIf="verEdad() < 85">
        
                                    La edad del usuario debe ser mayor o igual a 85 años para adquirir el plan.
                            </span>
                    </div>
        
                    <div class="col-md-4 mt-5">
                        <div class="form__group">
                            <input type="text" class="form__input" formControlName="nombreCompleto" placeholder="Nombre completo">
                            <label for="nombreCompleto" class="form__label">Nombre completo</label>
                            
                            <span class="form-text text-danger"
                                    *ngIf="campoInvalido('nombreCompleto')">

                                {{ msgError('nombreCompleto') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-5 mt-2">
                        <div class="form__group">
                            <label class="form-label">Tipo documento</label>
                                <select class="form__input" formControlName="tipoDocumento">
                                                                             
                                        <option value=""> -- Seleccione tipo de documento -- </option>
                                        <option value="CC">Cédula de Ciudadanía</option>
                                        <option value="CE">Cédula de Extranjería</option>
                                        <option value="PA">Pasaporte</option>
                                        <option value="RC">Registro Civil</option>
                                        <option value="TI">Tarjeta de Identidad</option>
                                </select>
        
                                <!-- ----------------------------------------- TIPO DOCUMENTO VALIDACIONES -------------------------------------- -->
                                <span class="form-text text-danger"
                                      *ngIf="campoInvalido('tipoDocumento')">
        
                                        {{ msgError('tipoDocumento') }}
                                </span>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form__group">
                            <input type="number" class="form__input" formControlName="numeroIdentificacion" placeholder="Numero identificacion">
                            <label for="numeroIdentificacion" class="form__label">Numero identificacion</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('numeroIdentificacion')">
        
                                    {{ msgError('numeroIdentificacion') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form__group">
                            <input type="number" class="form__input" formControlName="numeroCelular" placeholder="Numero celular">
                            <label for="numeroCelular" class="form__label">Numero celular</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('numeroCelular')">
        
                                    {{ msgError('numeroCelular') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form__group">
                            <input type="number" class="form__input" formControlName="numeroCelular2" placeholder="Numero celular 2">
                            <label for="numeroCelular2" class="form__label">Numero celular 2</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('numeroCelular2')">
        
                                    {{ msgError('numeroCelular2') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-5 mt-3">
                        <div>
                            <input autocomplete="off" class="form__input text-center" type="text" id="idmunicipio" placeholder="municipio/Departamento" (keyup)="deparMuniBuscar($event.target.value)" required>
                        </div>
                        <div class="panel-body list-group text-primary" style="background-color: black;">
                            <div class="seleccionado form-material" *ngFor="let dm of departamentos | busqueda: buscarDepasMunis" style="cursor: pointer;">
                                    <p class="text-center" (click)="selecionarMunicipio(dm)">
                                            {{ dm.nombreMunicipio }} - {{ dm.nombreDepartamento }}
                                    </p>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <div class="col-md-5 mt-3">
                        <div class="form__group">
                            <input type="text" class="form__input" formControlName="email" placeholder="Correo">
                            <label for="email" class="form__label">Correo</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('email')">
        
                                    {{ msgError('email') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form__group">
                            <input type="text" class="form__input" formControlName="direccion" placeholder="Direccion">
                            <label for="direccion" class="form__label">Direccion</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('direccion')">
        
                                    {{ msgError('direccion') }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form__group">
                            <input type="text" class="form__input" formControlName="barrio" placeholder="Barrio">
                            <label for="barrio" class="form__label">Barrio</label>
    
                            <span class="form-text text-danger"
                                      *ngIf="campoInvalido('barrio')">
        
                                    {{ msgError('barrio') }}
                            </span>
                        </div>
                    </div>

                </div>


            </form>

            
            <div class="modal" id="modalFormulario" role="dialog">
                <div class="modal-dialog modal-dialog-centered " style="background: transparent;">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <h3 style="font-family:'roboto'"> Los datos utilizados seran enviados a un asesor para confirmar la inscripcion. </h3>
                            <hr>
                            <h5> Confirma los siguientes datos ingresados? </h5>
                            <h5> CC: {{datosUsuario.get('numeroIdentificacion').value}} </h5>
                            <h5> Correo: {{datosUsuario.get('email').value}} </h5>
                            <h5> Telefono: {{datosUsuario.get('numeroCelular').value}} </h5>
        
                            <div class="text-center">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosB()">Si</button>
                                            
                                    <button type="button" class="btn btn-danger"data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-10">


                <div class="row">
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <!-- ------------------------------------------ CARD VALOR SUSCRIPCION ----------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <div class="col-10 mb-2 ml-5">
                        <div class="carta" style="padding-top: 15px;">
                            <div class="card border-dark rounded-3">
                                <div class="card-body">
                                    <h5 class="card-title">Resumen de suscripción</h5>
    
                                    <div class="row">
                                        <div class="col-8">
                                            <p>Valor total del plan</p>
                                        </div>
                                        <div class="col-4 text-right">$ {{valorSuscripcionTitular}}</div>
                                    </div>
    
                                    <div class="row">
                                        <div class="col-8">
                                            <p>numero de cuotas</p>
                                        </div>
                                        <div class="col-4 text-right">
                                            <select class="form-select form-selected-sm" id="cuotas" (change)="numCuotas()" required>
                                                <option selected> Cuotas </option>
                                                <option value="1"> 1 </option>
                                                <option value="2"> 2 </option>
                                                <option value="4"> 3 </option>
                                                <option value="4"> 4 </option>
                                                <option value="5"> 5 </option>
                                                <option value="6"> 6 </option>
                                                <option value="7"> 7 </option>
                                                <option value="8"> 8 </option>
                                                <option value="9"> 9 </option>
                                                <option value="10"> 10 </option>
                                                <option value="11"> 11 </option>
                                                <option value="12"> 12 </option>
                                                <option value="13"> 13 </option>
                                                <option value="14"> 14 </option>
                                                <option value="15"> 15 </option>
                                                <option value="16"> 16 </option>
                                                <option value="17"> 17 </option>
                                                <option value="18"> 18 </option>
                                            </select>
                                        </div>
                                    </div>
    
                                    <div class="row">
                                        <div class="col-8">
                                            <h5>Total</h5>
                                        </div>
                                        <div class="col-4 text-right">$ {{ valorTotal | number }}</div>
                                    </div>
    
                                    <div class="card-header"></div>
                                    <div class="text-center" style="padding-top: 5px;">
                                        <button class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#modalFormulario">
                                                Inscribirse
                                        </button>
                                    </div>

                                        <div class="mb-3 row">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                </symbol>
                                                </svg>

                                                <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="status === 'EXITO'">
                                                  <svg class="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
                                                  <div>
                                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                                <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    {{statusText}}
                                                  </div>
                                                </div>
                                        </div>

                                        <div class="mb-3 row">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                </symbol>
                                                </svg>

                                                <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="status === 'FALLO'">
                                                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                                                  <div>
                                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                          <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    {{statusText}}
                                                  </div>
                                                </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        </div>

    </div>


</div>

<app-footer></app-footer>

<!-- <h2>Valido: {{datosUsuario.valid}} </h2> -->