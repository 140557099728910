import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';


@Component({
  selector: 'app-info-datos',
  templateUrl: './info-datos.component.html',
  styleUrls: ['./info-datos.component.css']
})
export class InfoDatosComponent implements OnInit, OnDestroy {

  public id: string;
  public Datos;

  params: Subscription;

  constructor(private appService: AppService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit() {
    this.params = this.route.params.subscribe( (id: any) => {
      this.id = id.id
      console.log(this.id)
    } );
    this.obtenerContrato()
    this.router.navigate(['/infodatos', this.id])
  }
  
  obtenerContrato(){
    this.appService.infoContrato(this.id).subscribe( (datos:any) => {
      this.Datos = datos.body
      console.log(datos)
    } )
  }
  
  ngOnDestroy() {
    this.params.unsubscribe();
  }
}
