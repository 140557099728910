<!-- <app-menu></app-menu> -->

<div #contenedor id="contenedor" class="container">

    <div class="text-center">
        <h3 class="display-3 text-white" style="font-family: 'roboto';">PLAN LIBRANZA</h3>
    </div>

    <!-- // BTN OCULTO PARA SCROLL -->
    <button id="btn-scroll" style="display: none;" (click)="scroll()"></button>

    <div class="row" style="margin-top: 50px;">

    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- -------------------------------------------- FORMULARIO TITULAR ------------------------------------- -->
    <!-- ----------------------------------------------------------------------------------------------------- -->

    <div class="col-10 bg-white">

        <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
            {{statusText}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <form [formGroup]="datosForm">

            <div class="row g-3">

                <div class="col-md-4 mr-5">
                    <label class="form-label">Fecha Nacimiento</label>
                    <input  type="date"
                            class="form-control"
                            [max]="today"
                            formControlName="fechaNacimiento">

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('fechaNacimiento')">

                            {{ msgError('fechaNacimiento') }}
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="verEdad() < 18 || verEdad() > 65">

                            La edad del usuario ingresado es menor de 18 años o es mayor a 64 años
                    </span>
                </div>

                <div class="col-md-5">
                    <label class="form-label mr-3">Tipo de plan</label>
                    <br>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planBasico" data-toggle="modal" data-target="#modalPBasico" (change)="seleccionPlan()" >
                      <label class="form-check-label" for="planBasico"> <strong>Plan Basico</strong> </label>
                    </div>
                    <div class="form-check form-switch form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planGeneral" data-toggle="modal" data-target="#modalPGeneral" (change)="seleccionPlan()" >
                      <label class="form-check-label" for="planGeneral"> <strong>Plan General</strong> </label>
                    </div>
                    <hr>
                </div>

                <div class="modal" id="modalPBasico" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" style="background: transparent;">
                        <div class="modal-content">
                            <div class="modal-body text-center">
                                <h3 style="font-family: 'roboto';"> PLAN BÁSICO: 8 miembros de su familia. </h3>
                                <hr>
                                <h6 style="font-style: italic;"> Titular + beneficiarios hasta los 65 años – previamente inscritos, " los Padres máximo (2) dos hasta los 85 años “, ( 1 Peludito con carencia 180 días, valido solo para la ciudad de Pasto) aplican condiciones y restricciones”. </h6>
                                
                                <div class="text-center">
                                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="modalPGeneral" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" style="background: transparent;">
                        <div class="modal-content">
                            <div class="modal-body text-center">
                                <h3 style="font-family: 'roboto';"> PLAN GENERAL: 10 miembros de su familia. </h3>
                                <hr>
                                <h6 style="font-style: italic;"> Titular + beneficiarios hasta los 65 años – previamente inscritos “Padres o suegros (2) dos hasta los 85 los otros dos si los tuviese hasta los 75 años “(máximo 2 Peluditos con carencia 180 días, valido solo para la ciudad de Pasto)” aplican condiciones y restricciones. </h6>
                                
                                <div class="text-center">
                                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="col-md-4">
                    <label for="nombre" class="inp">
                        <input type="text" formControlName="nombreCompleto" placeholder="&nbsp;">
                        <span class="label">Nombre completo</span>
                        <span class="focus-bg"></span>
                    </label>

                    <!-- ----------------------------------- VALIDACION NOMBRES ----------------------------- -->
                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('nombreCompleto')">

                            {{ msgError('nombreCompleto') }}
                    </span>
                </div>

                <div class="col-md-4">
                    <label for="tipoDocumento" class="inp"> Tipo documento
                        <select class="form-control" formControlName="tipoDocumento" placeholder="&nbsp;">
                            
                            <option value=""> -- Seleccione tipo de documento -- </option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="PA">Pasaporte</option>
                            <option value="RC">Registro Civil</option>
                            <option value="TI">Tarjeta de Identidad</option>
                        </select>
                    </label>

                        <!-- ----------------------------------------- TIPO DOCUMENTO VALIDACIONES -------------------------------------- -->
                        <span class="form-text text-danger"
                              *ngIf="campoInvalido('tipoDocumento')">

                                {{ msgError('tipoDocumento') }}
                        </span>
                </div>

                <div class="col-md-4">
                    <label for="numeroIdentificacion" class="inp">
                        <input type="number" formControlName="numeroIdentificacion" min="0" placeholder="&nbsp;">
                        <span class="label">Numero documento</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('numeroIdentificacion')">

                            {{ msgError('numeroIdentificacion') }}
                    </span>
                </div>

                <div class="col-md-4">
                    <label for="email" class="inp">
                        <input type="email" formControlName="email" placeholder="&nbsp;">
                        <span class="label">Correo</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('email')">

                            {{ msgError('email') }}
                    </span>
                </div>

                <div class="col-md-4">
                    <label for="numeroCelular" class="inp">
                        <input type="number" formControlName="numeroCelular" min="0"placeholder="&nbsp;">
                        <span class="label">Numero celular</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('numeroCelular')">

                            {{ msgError('numeroCelular') }}
                    </span>
                </div>

                <div class="col-md-4">
                    <label for="numeroCelular2" class="inp">
                        <input type="number" formControlName="numeroCelular2" min="0"placeholder="&nbsp;">
                        <span class="label">Numero celular respaldo</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('numeroCelular2')">

                            {{ msgError('numeroCelular2') }}
                    </span>
                </div>

                <div class="col-md-4 mt-3">
                    <label for="municipio" class="inp">
                        <input autocomplete="off" class="form-control text-center" type="text" id="idmunicipio" placeholder="&nbsp;" (keyup)="deparMuniBuscar($event.target.value)" required>
                        <span class="label">Municipio/Departamento</span>
                        <span class="focus-bg"></span>
                    </label>
                    <div class="panel-body list-group text-primary" style="background-color: black;">
                        <div class="seleccionado form-material" *ngFor="let dm of departamentos | busqueda: buscarDepasMunis" style="cursor: pointer;">
                                <p class="text-center" (click)="selecionarMunicipio(dm)">
                                        {{ dm.nombreMunicipio }} - {{ dm.nombreDepartamento }}
                                </p>
                        </div>
                    </div>
                    <hr>
                </div>

                <div class="col-md-4">
                    <label for="direccion" class="inp">
                        <input type="text" formControlName="direccion" placeholder="&nbsp;">
                        <span class="label">Direccion</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('direccion')">

                            {{ msgError('direccion') }}
                    </span>
                </div>

                <div class="col-md-4">
                    <label for="barrio" class="inp">
                        <input type="text" formControlName="barrio" placeholder="&nbsp;">
                        <span class="label">Barrio</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('barrio')">

                            {{ msgError('barrio') }}
                    </span>
                </div>

                <div class="col-md-5">
                    <label for="profesion" class="inp">
                        <input type="text" formControlName="profesion" placeholder="&nbsp;">
                        <span class="label">profesion</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('profesion')">

                            {{ msgError('profesion') }}
                    </span>
                </div>

                <div class="col-md-5">
                    <label for="empresaDondeLabora" class="inp">
                        <input type="text" formControlName="empresaDondeLabora" placeholder="&nbsp;">
                        <span class="label">Empresa donde trabaja</span>
                        <span class="focus-bg"></span>
                    </label>

                    <span class="form-text text-danger"
                          *ngIf="campoInvalido('empresaDondeLabora')">

                            {{ msgError('empresaDondeLabora') }}
                    </span>
                </div>
                  
            </div>

        </form>

        <div class="col-md-5 mt-3" *ngIf="formBeneficiario.length > 4">
            <label class="form-label mr-3">Activar plan peluditos?</label>
            <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planPeluditosS" data-toggle="modal" data-target="#modalFPeluditos">
              <label class="form-check-label" for="planPeluditosS"> <strong>Si</strong> </label>
            </div>
            <div class="form-check form-switch form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="planPeluditosN" checked>
              <label class="form-check-label" for="planPeluditosN"> <strong>No</strong> </label>
            </div>
            <hr>
        </div>

        <div class="modal" id="modalFPeluditos" role="dialog">
            <div class="modal-dialog modal-dialog-centered" style="background: transparent;">
                <div class="modal-content" style="background: transparent;">
                    <div class="modal-body text-center">
                        <div class="text-center">
                        <app-form-peluditos></app-form-peluditos>
                        <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosPeludito()">Guardar</button>

                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ----------------------------------------------------------------------------------------------------- -->
        <!-- ----------------------------------------- FORMULARIOS BNEFICIARIO ----------------------------------- -->
        <!-- ----------------------------------------------------------------------------------------------------- -->

        <div *ngIf="formBeneficiario.length >= 1">

            <div  class="input-group"
                  *ngFor="let fb of formBeneficiario; let i = index">
    
                    <div class="row" style="margin-top: 40px;">
            
                        <div class="col-8  text-left">
                            <h3 class="card-title">Formulario beneficiario No. {{i + 1}}</h3>
                        </div>
                        <div class="col-4 text-right">
                            <button class="btn btn-danger" (click)="eliminarFormulario(i)">Eliminar</button>
                        </div>
    
                    </div>
    
                    <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
                        {{statusText}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
    
                    <form class="row g-3">
            
                        <div class="col-md-3">
                            <label class="form-label">Fecha nacimiento</label>
                            <input type="date" class="form-control" [max]="today" placeholder="Fecha de nacimiento." [id]="'fechaBeneficiario' + i">
                            <p [id]="'textfn' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                        </div>
    
                        <div class="col-md-4">
                            <label for="nombre" class="inp">
                                <input type="text" [id]="'nombreYapellido' + i" class="form-control" placeholder="&nbsp;">
                                <span class="label">Nombre completo</span>
                                <span class="focus-bg"></span>
                                <p [id]="'textn' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                                <p [id]="'textno' + i" style="color: red; font-size: 13px; display: none;">* Esta campo solo acepta letras.</p>
                            </label>
                        </div>
    
                        <div class="div-md-4">
                            <label class="form-label">tipo Documento</label>
                            <select class="form-control" [id]="'tipoDocumento' + i" (change)="cambioHtml($event, 'tipoDocumento', i)">                                             
                                    <option value=""> -- Seleccione tipo de documento -- </option>
                                    <option value="CC">Cédula de Ciudadanía</option>
                                    <option value="CE">Cédula de Extranjería</option>
                                    <option value="PA">Pasaporte</option>
                                    <option value="RC">Registro Civil</option>
                                    <option value="TI">Tarjeta de Identidad</option>
                            </select>
    
                            <!-- ----------------------------------------- TIPO DOCUMENTO VALIDACIONES -------------------------------------- -->
                            <p [id]="'texttdo' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                        </div>

                        <div class="col-md-4">
                            <label for="numeroDocumento" class="inp">
                                <input type="number" [id]="'numeroDocumento' + i" min="0" class="form-control" placeholder="&nbsp;" (keypress)="cambioHtml($event, 'numeroDocumento', i)">
                                <span class="label">Numero documento</span>
                                <span class="focus-bg"></span>
                                <p [id]="'textnd' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                                <p [id]="'textndo' + i" style="color: red; font-size: 13px; display: none;">* Número invalido.</p>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <label class="form-label">Parentesco</label>
                            <select class="form-control" [id]="'parentesco' + i" (change)="cambioHtml($event, 'parentesco', i)">
                                    <option disabled></option>
                                    <option *ngFor="let p of parentesco" [value]="p.nombreParentesco">{{p.nombreParentesco}}</option>
                            </select>
                            <p [id]="'textpa' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                        </div>

                        <div class="col-md-4">
                            <label for="numeroCelular" class="inp">
                                <input type="number" [id]="'numeroCelular' + i" min="0" class="form-control" placeholder="&nbsp;">
                                <span class="label">Numero celular</span>
                                <span class="focus-bg"></span>
                                <p [id]="'textnd' + i" style="color: red; font-size: 13px; display: none;">* Esta campo es requerido.</p>
                                <p [id]="'textndo' + i" style="color: red; font-size: 13px; display: none;">* Número invalido.</p>
                            </label>
                        </div>
            
                    </form>
            </div>

        </div>

        <div class="modal" id="modalFormulario" role="dialog">
                <div class="modal-dialog modal-dialog-centered " style="background: transparent;">
                         <div class="modal-content">
                                 <div class="modal-body text-center">
                                        <h3 style="font-family:'roboto'"> Los datos utilizados seran enviados a un asesor para confirmar la inscripcion. </h3>
                                        <hr>
                                        <h5 style="font-style: italic;"> Confirma los siguientes datos ingresados? </h5>
                                        <h5 style="font-style: italic;"> CC: {{datosForm.get('numeroIdentificacion').value}} </h5>
                                        <h5 style="font-style: italic;"> Correo: {{datosForm.get('email').value}} </h5>
                                        <h5 style="font-style: italic;"> Telefono: {{datosForm.get('numeroCelular').value}} </h5>

                                        <div class="text-center">
                                                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosB()">Si</button>

                                                <button type="button" class="btn btn-danger"data-dismiss="modal">No</button>
                                        </div>
                                 </div>
                         </div>
                </div>
        </div>

        <!------------------------------------------------------------------------------------------------------- -->
        <!------------------------------------------- BTN AGREGAR BNEFICIARIO ----------------------------------- -->
        <!------------------------------------------------------------------------------------------------------- -->
        <div class="text-center" style="padding-top: 30px;">
            <button class="btn btn-primary" (click)="agregarBeneficiario()">Agregar Beneficiario</button>
            <!--<button class="btn btn-primary" style="margin-left: 5px;" (click)="generarRecibo()">Generar recibo</button>-->
        </div>

        <div class="col-10">
            
                    <div class="row">
                        <!-- ----------------------------------------------------------------------------------------------------- -->
                        <!-- ------------------------------------------ CARD VALOR SUSCRIPCION ----------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------- -->
                        <div class="col-10 mb-2">
                            <div class="carta" style="padding-top: 15px;">
                                <div class="card border-dark rounded-3">
                                    <div class="card-body">
                                        <h3 class="card-title">Resumen de suscripción</h3>
        
                                        <div class="row">
                                            <div class="col-8">
                                                <p>Valor suscripcion por mes</p>
                                            </div>
                                            <div class="col-4 text-right">$ {{valorSuscripcion}}</div>
                                        </div>
        
                                        <div class="row">
                                            <div class="col-8">
                                                <p>Número de beneficiarios</p>
                                            </div>
                                            <div class="col-4 text-right">{{formBeneficiario.length}}</div>
                                        </div>
        
                                        <div class="row">
                                            <div class="col-8">
                                                <p>valor suscripcion al año</p>
                                            </div>
                                            <div class="col-4 text-right">$ {{valorSuscripcion * 12}}</div>
                                        </div>
        
                                        <div class="row">
                                            <div class="col-8">
                                                <h5>Total</h5>
                                            </div>
                                            <div class="col-4 text-right">$ {{(valorSuscripcion * formBeneficiario.length) + valorSuscripcion}}</div>
                                        </div>
        
                                        <div class="card-header"></div>
                                        <div class="text-center" style="padding-top: 5px;">
                                            <button class="btn btn-primary"
                                                    data-toggle="modal"
                                                    data-target="#modalFormulario">
                                                    Inscribirse
                                            </button>
                                        </div>

                                            <div class="mb-3 row">
                                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                    </symbol>
                                                    </svg>
    
                                                    <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="status === 'EXITO'">
                                                      <svg class="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
                                                      <div>
                                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                                    <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        {{statusText}}
                                                      </div>
                                                    </div>
                                            </div>

                                            <div class="mb-3 row">
                                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                    </symbol>
                                                    </svg>
    
                                                    <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="status === 'FALLO'">
                                                      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                                                      <div>
                                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                              <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        {{statusText}}
                                                      </div>
                                                    </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

    </div>

</div>

<app-footer></app-footer>
