<app-menu></app-menu>
<a target="_blank" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn-flotante">
  <h4 class="text-white mai-logo-whatsapp"></h4>
</a>
<div class="row">
  <div class=col-md-12>
      <img src="./assets/images/prevenir/familia.jpg" style="max-width: 100%; padding-top: 65px;  width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;">
  </div>
</div>

<div class="bg-image imgFondo">
  <div class="container">
    <div class="page-section">
    <div class="row">
        <div class="col-12">
            <h2 class="text-center text-white"> ¿Quienes Somos?</h2>
            <b style="font-size:18px;" class="mb-4 text-white"><p>Somos una corporación especializada en la coordinación de planes de <strong>Previsión Exequial</strong> individuales y masivos de las más prestigiosas empresas del sector público y privado. Como valor agregado contamos con una red de médicos
                especialistas y profesionales en todas las áreas que nos posicionan como la empresa con más servicios agregados para nuestros afiliados y así convertirnos en la más importante y sólida compañía de previsión funeraria del país.</p>
              </b>
              </div>
    </div>

    <br><br><br>
    <div class="row">
        <div class="col-6">
            <h2 class="text-center text-white"> Prevenir Express S.A.S.</h2>
            <h3 class="Subtitulo  text-white"> Objetivos</h3>
            <b style="font-size:18px;" class="mb-4 text-white">
            <p>Brindar opciones integrales que proporcionen tranquilidad a nuestros clientes y apoyárlos en cada uno de los procesos del duelo.</p>
            <p>Implementar día a día alianzas estratégicas que permitan satisfacer las necesidades de nuestros Afiliados de una manera automatizada implementando la tecnología para dicho fin.</p>
          </b>
        </div>
        <div class="col-6">
            <h2 class="text-center text-white"> Infraestructura aliada</h2>
            <b style="font-size:18px;" class="mb-4 text-white">
            <p> Contamos con más de 4.500 salas de velación en todo el país a su disposición en convenios directos o con terceros para cumplir con lo prometido.</p>
            </b>
          </div>
    </div>

    <div class="row">
        <div class="col-12">
          <br><br><br>
            <h2 class="text-center text-white"> Clientes aliados.</h2>
            <b style="font-size:18px;" class="mb-4 text-white">
            <p>Gracias a nuestras alianzas son más de 46.000 usuarios vinculados por libranza que cuentan con nuestra calidad en el Servicio: SECRETARIAS DE EDUCACION, GOBERNACIONES, ALCALDÍAS, ASOCIACIONES FONDOS DE EMPLEADOS “FOPEP”. “Entidades públicas
                y privadas” </p>
              </b>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
          <br><br><br>
            <h2 class="text-center text-white"> ¿Porque nos prefieren?</h2>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <p class="sliderPalabras text-center text-white">Por la tranquilidad que sienten al saber que en nuestras manos están Protegidos para cuando llegue el momento de la perdida de uno de sus seres queridos.</p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por que cuentan con el respaldo de una empresa solida responsable y comprometida con amor con su visión. </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por qué otorgamos soluciones inmediatas e integrales con herramientas tecnológicas y valores agregados que logran satisfacer y superar las expectativas de cada uno de nuestros afiliados. </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Porque Trabajamos por generar frutos día a día, que permitan el manejo sostenible de la compañía para preservar la integridad y los beneficios de nuestros afiliados.</p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por ir siempre actualizados con las nuevas tendencias del mercado funerario.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por tener un servicio con precios accequibles para toda la población Colombia.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por permitir escuchar sus sugerencias, asi reforzar los lazos con nuestros clientes, para quedarnos siempre en sus hogares como parte de su familia.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por ser cada día más humana y exigente la calidad de nuestro servicio.
                        </p>
                    </div>
                    <div class="carousel-item">
                        <p class="sliderPalabras text-center text-white">Por ser la única compañía con garantía del servicio funerario “6 meses para cualquier reclamación por inconformidad en cualquiera de los procedimientos del servicio con alguno de nuestras funerarias aliadas”.
                        </p>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>

        </div>
    </div>
  </div>
</div>


</div>
<app-footer></app-footer>
