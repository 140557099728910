import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-form-patriarca',
  templateUrl: './form-patriarca.component.html',
  styleUrls: ['./form-patriarca.component.css']
})
export class FormPatriarcaComponent implements OnInit {

  @ViewChild('contenedor', {static : true}) contenedor;

  public datosUsuario: FormGroup;
  public departamento = new FormControl ('', Validators.required);
  public buscarDepasMunis;
  public departamentos;
  public municipios;
  public intervalo;
  public valorSuscripcionTitular = 2480000;
  public valorTotal = 0;
  public cuotas;
  public today;
  public status: string;
  public statusText: string;

  constructor( private formBuilder: FormBuilder,
               private appService: AppService ) {
                 this.today = moment( new Date().toString()).format('YYYY-MM-DD')
                }

  ngOnInit() {
    this.inicializarFormulario();
    this.getDepartamentos();
    this.departamento.valueChanges.subscribe( (_) => {
      this.departamentoSelect()
    } );
  }

  inicializarFormulario() {
    this.datosUsuario = this.formBuilder.group({
      nombreCompleto : ['', [Validators.required, Validators.minLength(3), Validators.pattern('([a-zA-Z ñ]+) ([a-zA-Z ñ]+) ([a-zA-Z ñ]+)')]],
      tipoDocumento : ['', [Validators.required]],
      fechaNacimiento: [ '', [ Validators.required ] ],
      fechaBeneficiario: [ '' ],
      numeroIdentificacion : ['', [Validators.required,Validators.pattern('[0-9]*')] ],
      email : ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      numeroCelular : ['', [Validators.required, Validators.pattern('[0-9]*')]],
      numeroCelular2 : ['', [Validators.required, Validators.pattern('[0-9]*')]],
      municipio : ['', [Validators.required]],
      direccion : ['', [Validators.required]],
      barrio : ['', [Validators.required]],
    })
  }

  inicioPrueba()
  {
    this.datosUsuario.reset({
      nombreCompleto: 'asdfasd asdfas',
      numeroIdentificacion: 1241234124,
      email: 'asdfa@asdf.com',
      numeroCelular: 1231231,
      direccion: 'asdasd',
      barrio: 'asdasd',
    })
  }

  departamentoSelect() {
    this.selecionarMunicipio(this.departamento.value);
  }

  getDepartamentos() {
    this.appService.getDepartamentos().subscribe( (departamentos: any) => {
      this.departamentos = departamentos.body;
      //console.log(this.departamentos);
    }, err => {
    console.log('error get departamentos');
    } );
    
  }

  selecionarMunicipio(Municipio) {
    this.buscarDepasMunis = null;
    (<HTMLInputElement>document.getElementById('idmunicipio')).value = Municipio.nombreMunicipio + ' - ' + Municipio.nombreDepartamento;
    this.datosUsuario.get('municipio').setValue(Municipio.nombreMunicipio);
  }

  deparMuniBuscar(event:string){
    this.buscarDepasMunis = event
  }

  msgError( campo:string ){
    const errors = this.datosUsuario.get(campo).errors
    if ( errors.required ){
      return 'Este campo es requerido.'
    } else if ( errors.pattern ){
      return 'El formato ingresado es invalido.'
    }else if ( errors.minlength ) {
      return 'Cantidad minima de caracteres (7).'
    }

    return ''
  }

  campoInvalido( campo: string ){
    return this.datosUsuario.get(campo).invalid && this.datosUsuario.get(campo).touched
  }

  obtenerDatosB(){
    if (this.datosUsuario.valid && this.verEdad() > 84) {
      let info = { titular: this.datosUsuario.value, beneficiarios: 'Como es plan patriarca no tiene beneficiarios'}
      console.log(info)
  
      // this.appService.enviarDatos(info).subscribe();
  
      this.status = 'EXITO'
      this.statusText = 'Tus datos han sido enviados y una persona te llamara para completar la inscripcion'
    }else{
      this.status = 'FALLO'
      this.statusText = 'HA OCURRIDO UN ERROR! Tal vez no lleno completamente los datos o la edad del usuario es menor a 85 años, por favor verifique de nuevo.'
    }
  }

  verEdad(){
    let fecha = moment( this.datosUsuario.get('fechaNacimiento').value )
    let edad = parseInt(fecha.fromNow())
    //console.log(fecha)
    //console.log('la edad es: '+edad)

    return edad;
  }

  numCuotas(){
    this.cuotas = (<HTMLInputElement>document.getElementById('cuotas')).value
    //console.log(this.cuotas)
    this.valorTotal = this.valorSuscripcionTitular/this.cuotas;
    this.valorTotal = Math.floor(this.valorTotal);
    this.valorTotal = Math.round(this.valorTotal);
  }

  cerrarAlerta() {
    this.statusText = '';
    this.status = '';
  }
  
  scroll() {
    let coordenadaY: number;
    coordenadaY = this.contenedor.nativeElement.offsetHeight;
    // console.log('aqui scroll', coordenadaY);
    window.scrollTo(0 , coordenadaY);
    clearInterval(this.intervalo);
  }

}
