<!-- <app-menu></app-menu> -->

<div #contenedor id="contenedor" class="container">

    <div class="text-center">
        <h3 class="display-3 text-white" style="font-family: 'roboto';">PLAN INTERNACIONAL</h3>
    </div>

    <button id="btn-scroll" style="display: none;" (click)="scroll()"></button>

    <div class="row bg-white" style="margin-top: 50px; ">

        <div class="col-10">

            <div class="alert alert-danger alert-dismissible fade show container" role="alert" *ngIf="status === 'error'">
                {{statusText}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form [formGroup]="datosUsuario">
                <div class="row g-4">
    
                    <span>
                        <input class="basic-slide" formControlName="nombreCompleto" type="text" placeholder="Nombre Completo" /><label for="nombreCompleto">Nombre completo</label>
                    </span>
                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('nombreCompleto')">

                            {{ msgError('nombreCompleto') }}
                    </span>

                    <span>
                        <select class="gate" formControlName="tipoDocumento" >

                            <option value=""> Seleccione tipo de documento </option>
                            <option value="CC">Cédula de Ciudadanía</option>
                            <option value="CE">Cédula de Extranjería</option>
                            <option value="PA">Pasaporte</option>
                            <option value="RC">Registro Civil</option>
                            <option value="TI">Tarjeta de Identidad</option>

                        </select>
                        <label for="tipoDocumento">Tipo documento</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('tipoDocumento')">

                            {{ msgError('tipoDocumento') }}
                    </span>

                    <span>
                        <input class="slide-up" formControlName="numeroDocumento" minlength="5" type="number" placeholder="Numero documento"><label for="numeroDocumento">Numero documento</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('numeroDocumento')">

                            {{ msgError('numeroDocumento') }}
                    </span>

                    <span>
                        <input class="slide-up" formControlName="fechaNacimiento" type="date" placeholder="Fecha de nacimiento"><label for="fechaNacimiento">Fecha nacimiento</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('fechaNacimiento')">

                            {{ msgError('fechaNacimiento') }}
                    </span>

                    <span>
                        <input class="slide-up" formControlName="email" type="email" placeholder="Correo"><label for="email">Correo</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('email')">

                            {{ msgError('email') }}
                    </span>

                    <span>
                        <input class="card-slide" formControlName="numeroCelular" type="number" minlength="7" placeholder="Numero de Celular"><label for="numeroCelular">Numero telefono</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('numeroCelular')">

                            {{ msgError('numeroCelular') }}
                    </span>

                    <span>
                        <input class="card-slide" formControlName="numeroCelular2" type="number" minlength="7" placeholder="Numero de Celular"><label for="numeroCelular2">Numero respaldo</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('numeroCelular2')">

                            {{ msgError('numeroCelular2') }}
                    </span>

                    <span>
                        <input class="balloon text-center" type="text" formControlName="pais" placeholder="Pais residencia"><label for="pais">Pais residencia</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('pais')">

                            {{ msgError('pais') }}
                    </span>

                    <span>
                        <input class="gate" formControlName="direccion" type="text" placeholder="Direccion"><label for="direccion">Direccion</label>
                    </span>

                    <span class="form-text text-danger"
                            *ngIf="campoInvalido('direccion')">

                            {{ msgError('direccion') }}
                    </span>
    
                </div>
            </form>


            <div class="modal" id="modalFormulario" role="dialog">
                <div class="modal-dialog modal-dialog-centered " style="background: transparent;">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <h3 style="font-family:'roboto'"> Los datos utilizados seran enviados a un asesor para confirmar la inscripcion. </h3>
                            <hr>
                            <h5> Confirma los siguientes datos ingresados? </h5>
                            <h5> CC: {{datosUsuario.get('numeroDocumento').value}} </h5>
                            <h5> Correo: {{datosUsuario.get('email').value}} </h5>
                            <h5> Telefono: {{datosUsuario.get('numeroCelular').value}} </h5>
        
                            <div class="text-center">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obtenerDatosB()">Si</button>
                                            
                                    <button type="button" class="btn btn-danger"data-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-10">


                <div class="">
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <!-- ------------------------------------------ CARD VALOR SUSCRIPCION ----------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------- -->
                    <div class="col-12 mb-2 ml-5">
                        <div class="carta" style="padding-top: 15px;">
                            <div class="card border-dark rounded-3">
                                <div class="card-body">
                                    <h5 class="card-title">Resumen de suscripción</h5>
    
                                    <div class="">
                                        <div class="col-8">
                                            <p>Valor del plan al año</p>
                                        </div>
                                        <div class="col-4 text-right">$ {{valorSuscripcionTitular | number}}</div>
                                    </div>

                                    <div class="col-8">
                                        <p>valor por mes</p>
                                    </div>
                                    <div class="col-4 text-right">$ {{valorSuscripcionMes | number}}</div>
    
                                    <div class="">
                                        <div class="col-8">
                                            <h5>Total</h5>
                                        </div>
                                        <div class="col-4 text-right">$ {{ valorSuscripcionTitular | number }}</div>
                                    </div>
    
                                    <div class="card-header"></div>
                                    <div class="text-center" style="padding-top: 5px;">
                                        <button class="btn btn-primary"
                                                data-toggle="modal"
                                                data-target="#modalFormulario">
                                                Inscribirse
                                        </button>
                                    </div>

                                        <div class="mb-3 ">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                </symbol>
                                                </svg>

                                                <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="status === 'EXITO'">
                                                  <svg class="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
                                                  <div>
                                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                                <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    {{statusText}}
                                                  </div>
                                                </div>
                                        </div>

                                        <div class="mb-3 ">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                </symbol>
                                                </svg>

                                                <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="status === 'FALLO'">
                                                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                                                  <div>
                                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="cerrarAlerta()">
                                                          <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    {{statusText}}
                                                  </div>
                                                </div>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<app-footer></app-footer>