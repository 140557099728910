<app-menu></app-menu>
<a target="_blank"  href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn-flotante">
	<h4 class="text-white mai-logo-whatsapp"></h4> </a>
<div class="row">
	<div class=col-md-12> <img src="./assets/images/prevenir/familia.jpg" style="max-width: 100%; padding-top: 65px;  width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;"> </div>
</div>
<div class="bg-image imgFondo">
	<div class="">
		<div class="row">
			<div class=col-md-1></div>
			<div class="col-md-10">
				<br>
				<h2 class="text-center tituloPrincipal">Contáctenos y PQRSF</h2> </div>
			<div class=col-md-1></div>
		</div>
		<div class="row">
			<div class=col-md-1></div>
			<div class="col-md-6 text-center">
				<h2 class="text-center">Nuestra ubicación</h2>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d498.6147506521222!2d-77.28017171404974!3d1.2172127996104607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed56157d128f7%3A0xf07199cd158e6300!2sGRUPO%20PREVENIR%20EXPRESS%20-%20%C3%93PTICA%20PREVENIR!5e0!3m2!1ses-419!2sco!4v1622212114067!5m2!1ses-419!2sco" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
			</div>
			<div class="col-md-4">
				<h2 class="Subtitulo" style="text-align: center !important;"> Contactanos</h2> <b class="mb-3"></b>
				<ul class="menu-link">
					<li><b class="text-white">Atención al cliente:</b></li>
					<li><a class="text-white">(2)7290038 - +57 310 435 1937 - +57 317 264 5522</a></li>
					<li><b class="text-white">Correo:</b></li>
					<li><a class="text-white">usuarios@prevenirexpress.com</a></li>
					<li><b class="text-white">Dirección:</b></li>
					<li><a class="text-white">Calle 18 # 27 - 74 CENTRO.</a></li>
				</ul>
			</div>
			<div class=col-md-1></div>
		</div>
		<br /> </div>
</div>
<app-footer></app-footer>
