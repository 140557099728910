<div #contenedor id="contenedor" class="container" *ngIf="Datos">
    <div class="text-center">
        <h4 class="display-4 text-primary" style="font-family: 'roboto';">INFORMACION DATOS</h4>
    </div>

    <div class="row" style="margin-top: 50px;">
        
        <div class="card border-primary rounded-3 col-md-5" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title text-center"> <strong> Datos del titular </strong> </h5>
            <div [routerLink]="['/infodatos/', id]">

                <div class="mb-3 row">
                  <label class="form-label">Nombre</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.nombreCompleto}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Tipo Documento</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.tipoDocumento}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Numero documento</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.numeroIdentificacion}}></strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Fecha de nacimiento</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.fechaNacimiento | date}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Email</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.email}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Numero Celular</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.numeroCelular}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Numero respaldo</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.numeroCelular2}}</strong>
                  </div>
                </div>
                
                <div class="mb-3 row">
                  <label class="form-label">Municipio</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.municipio}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Direccion</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.direccion}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Barrio</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.barrio}}</strong>
                  </div>
                </div>
                
                <div class="mb-3 row">
                  <label class="form-label">profesion</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.profesion}}</strong>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Empresa</label>
                  <div class="col-sm-10">
                    <strong>{{Datos.titular.empresaDondeLabora}}</strong>
                  </div>
                </div>

            </div>
          </div>
        </div>

        <div class="card border-danger rounded-3 col-md-5 ml-5" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title text-center"> <strong> Datos de la empresa </strong> </h5>

            <div class="mb-3 row">
              <label class="form-label">Nombre</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.nombreEmpresa}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">NIT</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.nitEmpresa}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">Correo</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.correoEmpresa}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">NUmero telefono</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.numeroEmpresa}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">NUmero respaldo</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.numeroEmpresa2}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">Municipio</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.municipio}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">Direccion</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.direccionEmpresa}}</strong>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="form-label">Barrio</label>
              <div class="col-sm-10">
                <strong>{{Datos.titular.barrio}}</strong>
              </div>
            </div>

          </div>
        </div>


        <div class="card border-primary rounded-3 mt-3 col-md-5" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title text-center"><strong> Datos del beneficiario </strong></h5>
            <div *ngFor="let item of Datos.beneficiarios; let i = index" >

              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button btn-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Beneficiario #{{i+1}}
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      
                      <div class="mb-3 row">
                        <label class="form-label">Nombre</label>
                        <div class="col-sm-10">
                          <strong>{{item.nombreYapellido}}</strong>
                        </div>
                      </div>
      
                      <div class="mb-3 row">
                        <label class="form-label">Tipo documento</label>
                        <div class="col-sm-10">
                          <strong>{{item.tipoDocumento}}</strong>
                        </div>
                      </div>
      
                      <div class="mb-3 row">
                        <label class="form-label">Numero documento</label>
                        <div class="col-sm-10">
                          <strong>{{item.numeroDocumento}}</strong>
                        </div>
                      </div>
      
                      <div class="mb-3 row">
                        <label class="form-label">Fecha nacimiento</label>
                        <div class="col-sm-10">
                          <strong>{{item.fechaBeneficiario | date}}</strong>
                        </div>
                      </div>
      
                      <div class="mb-3 row">
                        <label class="form-label">Parentesco</label>
                        <div class="col-sm-10">
                          <strong>{{item.parentesco}}</strong>
                        </div>
                      </div>
      
                      <div class="mb-3 row">
                        <label class="form-label">Numero celular</label>
                        <div class="col-sm-10">
                          <strong>{{item.numeroCelular}}</strong>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>  
              <hr>
            </div>
            
          </div>
        </div>

    </div>
</div>