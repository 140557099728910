<div class="container">
<div class="row">
<div class="col-xs-12 col-sm-6 col-md-4 mx-auto">
    <div class="card_login">
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="validacion==='mal'">
                    Usuario o Contraseña incorrectos
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
        <div class="card">
            <div class="card-body text-center">
                <form class="form" (ngSubmit)="login()">
                    <div class="form-group">
                            <input type="text" [formControl]="username" id="username" name="username" class="form-control" placeholder="usuario" required>
                    </div>
                   <div class="form-group">
                        <input type="password" [formControl]="password" id="password" name="password" class="form-control" placeholder="contraseña" required>
                   </div>

                    <button id="login-button" type="submit" class="btn btn-lg btn-primary btn-block">Login</button>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
</div>