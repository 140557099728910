<app-menu></app-menu>
<a target="_blank" href="https://api.whatsapp.com/send?phone=573104351937&amp;text=Hola!%20quiero%20saber%20más%20de%20Prevenir%20Express!" class="btn-flotante">
  <h4 class="text-white mai-logo-whatsapp"></h4>
</a>

<!-- <a target="_blank" href="http://www.prevenirexpress.com/planes" class="btn-floating" role="button" data-bs-toggle="button"> <h3>Planes</h3> </a> -->

<div class="row">
  <div class="col-md-12">
      <div style="background-color: #1F2531;padding-top: 80px;" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active"> <img src="./assets/images/prevenir/banners/banner_1.png"  class="d-block w-100" alt="Prevenir Express Información"> </div>
            <div class="carousel-item"> <img src="./assets/images/prevenir/banners/banner_2.png" class="d-block w-100" alt="Prevenir Express Líneas de emergencia funeraria"> </div>
            <div class="carousel-item"> <img src="./assets/images/prevenir/banners/banner_3.png" class="d-block w-100" alt="Prevenir Express Óptica"> </div>
            <div class="carousel-item"> <img src="./assets/images/prevenir/banners/banner_4.png" class="d-block w-100" alt="Prevenir Express Líneas de emergencia funeraria"> </div>
            <div class="carousel-item"> <img src="./assets/images/prevenir/banners/banner_5.png" class="d-block w-100" alt="Prevenir Express Redes Sociales"> </div>

          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
          </a>

      </div>
  </div>
</div>
<div class="position-realive bg-image imgFondo">
	<div class="container">
		<div class="page-section">
			<div class="row ">
				<div class="col-md-12 col-sm-12">
					<h1 class="text-center text-white"> Bienvenido a su empresa de prevision exequial </h1>
					<br>
					<br> </div>
			</div>
			<div class="row">
				<div class="col-md-1"></div>
				<br />
				<div class="col-md-5 col-sm-12">
					<h3 class="text-white">Misión</h3> <b  class="mb-4 text-white">
                <p>Tener el mejor servicio con calidad humana y consientes del valor supremo de las personas, asi recibir con responsabilidad la confianza que depositan nuetros clientes para cuando llegue el día de la pérdida de un ser querido. </p>
                </b> </div>
				<div class="col-md-5 col-sm-12">
					<h3 class="text-white">Visión</h3> <b  class="mb-4 text-white">
                <p>Lograr que todo integrante de cada núcleo familiar este protegido con nuestro plan de previsión funeraria, así dar tranquilidad a los hogares colombianos.</p>
                </b> </div>
				<div class="col-md-1"></div>
			</div>
			<div class="row">
				<br>
				<div class="col-md-1"></div>
				<br />
				<div class="col-md-10">
					<h3 class="text-white text-center">¿Quiénes somos?</h3> <b  class="mb-4 text-white">
                <p><strong>GRUPO PREVENIR EXPRESS S.A.S. </strong> Somos una Corporación especializada en planes de Previsión Funeraria, individuales y masivos de las más prestigiosas empresas del sector público y privado.
                    <strong>GRUPO PREVENIR EXPRESS S.A.S. </strong> Nació con el objetivo de desarrollar y promover la importancia de la Previsión Funeraria en Colombia. </p>
                    <br><br>
                    <h4 style="font-style: italic; text-align: center;">¡PARA NOSOTROS SU TRANQUILIDAD LO MÁS IMPORTANTE.!
                </h4>
                </b>
					<br>
					<br>
					<br>
					<br> </div>
				<div class="col-md-1"></div>
			</div>
		</div>
		<div class="">
			<div class="row">
				<div class="col-md-12">
					<h1 class="text-center "> Calidad de servicio </h1>
					<h3 class="text-center "> ¿Que entregamos en un servicio funerario?</h3>
					<div class="">
						<div class="">
							<div class="row">
								<div class="col-lg-6 py-3">
									<div style="display: flex;align-items: center;justify-content: center;padding-top: 5%;" class="shadow wow zoomIn"> <img src="./assets/images/prevenir/cofre.png" class="imagenPrev"> </div>
								</div>
								<div class="col-lg-6 py-3 mt-lg-5 wow fadeInUp">
									<h3 class=" text-white text-center">Servicios iniciales</h3> <b  class="mb-4 text-white">

                        <ul>
                          <li>Retiro del cuerpo en el lugar de fallecimiento. </li>
                          <li>Preparación y conservación del cuerpo para 24 horas.</li>
                          <li>Cofre fúnebre Tipo plan (en 2 referencias). </li>
                          <li>Trámites legales y notariales.</li>
                          <li>Traslado del cuerpo dentro del municipio o nacional.</li>
                        </ul>
                              </b> </div>
							</div>
						</div>
					</div>
					<div class="">
						<div class="">
							<div class="row">
								<div class="col-lg-6 py-3 mt-lg-5 wow fadeInUp">
									<h3 class=" text-white text-center">Servicios finales o velación</h3> <b  class="mb-4 text-white">

                        <ul>
                            <li>Sala de velación por 24 horas.</li>
                            <li>Serie de carteles.</li>
                            <li>Cinta impresa con el nombre de la persona fallecida.</li>
                            <li>Arreglo floral con el nombre del GRUPO PREVENIR EXPRESS SAS.</li>
                            <li>Servicio de cafetería “café y aromática”.</li>
                            <li>Suministro de libro recordatorio de firmas de asistentes.</li>
                            <li>Rosario y guía para oración.</li>
                            <li>Traslado del cuerpo al sitio de velación y ceremonia en carroza fúnebre.</li>
                            <li>Ceremonia Religiosa (cualquier credo).</li>
                            <li>Un vehículo para transporte de acompañantes “para ciudades principales y perímetro urbano”.</li>
                        </ul>
                              </b> </div>
								<div class="col-lg-6 py-3">
									<br>
									<br>
									<div style="display: flex;align-items: center;justify-content: center;padding-top: 15%;" class=" shadow wow zoomIn d-none d-lg-block"> <img src="./assets/images/prevenir/cruz.png" class="imagenPrev"> </div>
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<div class="">
							<div class="row">
								<div class="col-lg-6 py-3">
									<div style="display: flex;align-items: center;justify-content: center;padding-top: 5%;" class="shadow wow zoomIn d-none d-lg-block"> <img src="./assets/images/prevenir/cementerio.png" class="imagenPrev"> </div>
								</div>
								<div class="col-lg-6 py-3 mt-lg-5 wow fadeInUp">
									<h3 class=" text-white text-center">Destino final o cementerio</h3> <b  class="mb-4 text-white">
                        <ul>
                          <li>Bóveda o lote en uso temporal</li>
                          <li>Cementerios Distritales o municipales</li>
                          <li>Cremación y cofre cenízario.</li>
                          <li>Parque cementerio </li>
                        </ul>
                        </b> </div>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
					<br>
					</div>
				<div class="container">
					<div class="page-section">

								<div class="row">
									<div class="col-md-6 ">
										<li class="text-white">Carencias: aplican para todos los planes. </li>
										<li class="text-white">Las carencias es el tiempo que NO está activo el servicio. “el objetivo del periodo de carencia es controlar la malversación y evitar que un cliente que haya sido diagnosticado previamente con alguna condición médica, enfermedad terminal o lecho de muerte, contrate un servicio de previsión Exequial con el objeto de acceder de manera INMEDIATA al servicio”.</li>
										<li class="text-white">72 horas para muerte accidental.</li>
										<li class="text-white">90 días para muerte natural.</li>
										<li class="text-white">180 días para enfermedades pre-existentes. </li>
									</div>
									<div class="col-md-6">
										<h3 class=" text-white">Cubrimiento de los servicios funerarios y cementerios.</h3>
										<li class="text-white">Cubrimiento a nivel nacional</li>
										<li class="text-white">Carné informativo entregado en el momento de la vinculación. </li>
										<li class="text-white">aplican condiciones y restricciones </li>
									</div>
								</div>
							</div>

			</div>
		</div>
		<div class="container-fluid galeria3d">
			<div class="row" style="padding: 50px">
				<div class="col-md-12">
					<carousel-component [autoPlay]="true" [mode]="'horizontal'" #topCarousel class="carousel3D">
						<div class="item-carousel">
							<div> <img class="w-100" src="./assets/img/prevenirInfo1.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo2.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo3.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" style="border: none;" src="./assets/img/prevenirInfo4.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo5.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo6.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo7.png" /> </div>
						</div>
						<div class="item-carousel">
							<div class="carousel3D"> <img class="w-100" src="./assets/img/prevenirInfo8.png" /> </div>
						</div>
					</carousel-component>
				</div>
			</div>
		</div>
		<div class="container-fluid galeria3d2">
			<div class="row">
				<div class="col-md-2"> </div>
				<div class="col-md-8">
					<header>
						<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
							<div class="carousel-inner" role="listbox">
								<div class="carousel-item propCarousel active" style="background-image: url('./assets/img/prevenirInfo1.png')">
									<div class="carousel-caption d-none d-md-block"></div>
								</div>
								<div class="carousel-item propCarousel " style="background-image: url('./assets/img/prevenirInfo2.png')">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo3.png')">
									<div class="carousel-caption d-none d-md-block"></div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo4.png');">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo5.png')">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo6.png')">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo7.png')">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
								<div class="carousel-item propCarousel" style="background-image: url('./assets/img/prevenirInfo8.png')">
									<div class="carousel-caption d-none d-md-block"> </div>
								</div>
							</div>
							<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a>
							<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a>
						</div>
					</header>
				</div>
				<div class="col-md-2"> </div>
			</div>
		</div>
		<button type="button" style="display: none;" id="btnModal" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button>
		<!-- Modal -->
		<div class="modal " id="myModal" role="dialog">
      		<div class="modal-dialog modal-dialog-centered " style="background: transparent;">
        		<div class="modal-content" style="background: transparent;">
					<div class="">
						<div class="modal-body text-center">
            				<img src="./assets/images/prevenir/gifangel_2.gif"  width="150%"> 
						</div>
						<div class="text-center">
            				<button type="button" class="btn btn-danger"data-dismiss="modal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	<a href="http://www.prevenirexpress.com/planes" class="btn btn-primary btn-lg btn-flotante" style="float: left; margin: 80px 0px;" role="button">Planes</a>
	
	<app-footer></app-footer>
